import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Container, Row, Col, Card, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getUsers, addUser, deleteUser } from './UsersActions';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';
import Loading from '../../components/Loading/Loading';
import ImageSize from '../../components/ImageSize/ImageSize';

import profileImage from '../../assets/images/default-image.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import warningIcon from '../../assets/images/warning.svg';

import styles from './Users.scss';
let cx = classNames.bind(styles);

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getUsers();
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleSubmit = (ev) => {
    this.props
      .addUser({
        name: this.state.name,
        email: this.state.email,
      })
      .then(() => {
        this.setState({
          name: '',
          email: '',
        });
      })
      .catch(() => {});
  };

  render() {
    const { users, isLoading, isAdding } = this.props;

    const tooltip = <Tooltip>Not activated</Tooltip>;

    return (
      <div>
        <Helmet title="Users" />

        <Container>
          <Row>
            <Col>
              <Card className={cx('card_invite_user')}>
                <Card.Header as="h5">Invite User</Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Group>
                        <Form.Control
                          name="name"
                          type="text"
                          id="name"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                        <Form.Label htmlFor="name">
                          <span>Name</span>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group xs={12} md={12} lg={6}>
                        <Form.Control
                          name="email"
                          type="email"
                          id="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <Form.Label htmlFor="email">
                          <span>Email</span>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <p className={cx('role')}>Role</p>
                  <Form.Check custom type="radio" id="admin" label="Admin (full access)" />
                  <Form.Check custom type="radio" id="viewer" label="Viewer (no access to Billing and Users)" /> */}
                  <Row>
                    <Col className={cx('action_btn')}>
                      <Link to="/" className="btn btn-blue-border btn-sm">
                        Back
                      </Link>
                      <Button
                        variant="dark-blue"
                        size="sm"
                        onClick={() => {
                          this.handleSubmit();
                        }}
                      >
                        {isAdding ? 'Saving...' : 'Send Invitation'}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className={cx('card_users')}>
                <Card.Header as="h5">Users</Card.Header>
                <Card.Body>
                  <div className={cx('users_list')}>
                    {users &&
                      users.map((user, i) => {
                        return (
                          <div key={i} className={cx('user_box')}>
                            <div className={cx('user_content')}>
                              <div className={cx('avatar')}>
                                <ImageSize src={user.photo || profileImage} alt="#" />
                              </div>
                              <h2>{user.name}</h2>
                              <p>
                                <span className={cx('email')}>{user.email}</span>
                                {!user.activated && (
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <span className={cx('warning')}>
                                      <img src={warningIcon} alt="pending" />
                                    </span>
                                  </OverlayTrigger>
                                )}
                              </p>
                            </div>
                            <Confirm
                              body={
                                <p>
                                  Are you sure you want to remove <br />
                                  <strong>{user.name}</strong> from this account?
                                </p>
                              }
                              confirmText="Remove"
                              icon={deleteIcon}
                              title="Remove user"
                              onConfirm={() => this.props.deleteUser(user._id)}
                            >
                              <Button className={cx('btn', 'btn_icon')}>
                                <img src={deleteIcon} alt="delete" />
                              </Button>
                            </Confirm>
                          </div>
                        );
                      })}
                    {isLoading && (
                      <div className={cx('table-loading')}>
                        <Loading />
                      </div>
                    )}
                    {!isLoading && users.length === 0 && (
                      <div className={cx('no_data')}>
                        <p> No data available </p>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    addUser: (model) => dispatch(addUser(model)),
    deleteUser: (id) => dispatch(deleteUser(id)),
  };
};

function mapStateToProps(state) {
  return {
    users: state.users.list,
    isLoading: state.users.isLoading,
    isAdding: state.users.isAdding,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
