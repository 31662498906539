import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Button, Pagination, Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';
import classNames from 'classnames/bind';

import Loading from '../../../components/Loading/Loading';
import throttle from '../../../utils/throttle';
import pagination from '../../../utils/pagination';
import { getStatus, promoteJobs, createJobs, cleanJobs, remapJobs, cleanMeta, cleanUp, retryJobs, removeJobs, cleanWebhook } from '../StatusActions';
import CheckBox from '../../../components/CheckBox/CheckBox';
import EllipsisWithTooltip from '../../../components/EllipsisWithTooltip';

import resyncIcon from '../../../assets/images/sync.svg';

import styles from '../../../assets/styles/_table.scss';
let cx = classNames.bind(styles);

class StatusTable extends Component {
  static propTypes = {
    pagination: PropTypes.bool,
    show: PropTypes.number,
  };

  static defaultProps = {
    pagination: true,
    show: 20,
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      dateFormat: 'DD MMM yyyy HH:mm z',
      search: '',
      app: 'jira',
      appOptions: [
        { name: 'Jira', value: 'jira' },
        { name: 'Trello', value: 'trello' },
        { name: 'Asana', value: 'asana' },
        { name: 'Azure', value: 'azure' },
      ],
      subs: 'ALL',
      subsOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Trial', value: 'TRIAL' },
        { name: 'Trial Expired', value: 'EXPIRED' },
        { name: 'Cancelled', value: 'CANCELLED' },
      ],
      status: 'ALL',
      statusOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'Healthy', value: 'HEALTHY' },
        { name: 'Sick', value: 'SICK' },
      ],
      action: 'ALL',
      actionOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'Clean', value: 'CLEAN' },
        { name: 'Resync', value: 'RESYNC' },
        { name: 'Retry', value: 'RETRY' },
        { name: 'Create Jobs', value: 'CREATE' },
        { name: 'Remap Jobs', value: 'REMAP' },
        { name: 'Clean Webhook', value: 'WEBHOOK' },
      ],
      limit: 100,
      limitOptions: [
        { name: '10', value: 10 },
        { name: '50', value: 50 },
        { name: '100', value: 100 },
        { name: '500', value: 500 },
        { name: '1000', value: 1000 },
      ],
      webhook: 'ALL',
      webhookOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'No webhook', value: 'FALSE' },
        { name: 'Has webhook', value: 'TRUE' }
      ],
      pullJob: 'ALL',
      pullJobOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'No pull job', value: 'FALSE' },
        { name: 'Has pull job', value: 'TRUE' }
      ],
      weeklyJob: 'ALL',
      weeklyJobOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'No weekly job', value: 'FALSE' },
        { name: 'Has weekly job', value: 'TRUE' }
      ],
      issue: 'ALL',
      issueOptions: [
        { name: 'All', value: 'ALL' },
        { name: 'Weekly not found', value: 'WEEKLY JOB NOT FOUND' },
        { name: 'Weekly not mapped', value: 'WEEKLY JOB NOT MAPPED' },
        { name: 'Weekly meta gone', value: 'WEEKLY META NOT FOUND' },
        { name: 'Weekly is failed', value: 'WEEKLY JOB IS FAILED' },
        { name: 'Weekly is stuck', value: 'WEEKLY JOB IS STUCK' },
        { name: 'Weekly wrong user', value: 'WEEKLY USER IS WRONG' },
        { name: 'Multiple weekly', value: 'MULTIPLE WEEKLY JOBS' },
        { name: 'Pull not found', value: 'PULL JOB NOT FOUND' },
        { name: 'Pull not mapped', value: 'PULL JOB NOT MAPPED' },
        { name: 'Pull is failed', value: 'PULL JOB IS FAILED' },
        { name: 'Pull is stuck', value: 'PULL JOB IS STUCK' },
        { name: 'Pull is not required', value: 'PULL JOB NOT REQUIRED' },
        { name: 'Pull meta gone', value: 'PULL META NOT FOUND' },
        { name: 'Pull wrong user', value: 'PULL USER IS WRONG' },
        { name: 'Multiple pulling', value: 'MULTIPLE PULL JOBS' },
        { name: 'No webhook', value: 'NO WEBHOOK' },
        { name: 'Remove Webhook', value: 'WEBHOOK IS NOT REQUIRED' },
        { name: 'No access', value: 'NO ACCESS' },
        { name: 'No user', value: 'NO USER' },
        { name: 'No board', value: 'NO BOARD' },
      ],
      selected: {},
      toCleanUp: [],
      toCleanMeta: [],
      toCreatePull: [],
      toRemove: [],
      toResync: [],
    };

    this.getStatusThrottled = throttle(this.getStatus, 500, this);
  }

  componentDidMount() {
    this.getStatus();
  }

  handleChange = (ev) => {
    this.setState({ search: ev.target.value, page: 1 });
    this.getStatusThrottled();
  };

  goto = (page = 1) => {
    if (page < 1) {
      page = 1;
    }
    this.setState({ page }, this.getStatus);
  };

  getStatus = () => {
    this.setState({ selected: {} });
    this.props.getStatus(this.getOpts());
  };

  getOpts = () => {
    const { search, page, app, subs, limit, webhook, pullJob, weeklyJob } = this.state;
    return {
      app,
      subs,
      search,
      webhook,
      pulling: pullJob,
      weekly: weeklyJob,
      page,
      limit
    };
  }

  retry = (id) => {
    const ids = [id];
    console.log('RETRY', ids);
    this.props.retryJobs(ids, this.getOpts());
  }

  promote = (id) => {
    const ids = [id];
    console.log('PROMOTE', ids);
    this.props.promoteJobs(ids, this.getOpts());
  }

  create = (id, pulling = false) => {
    const ids = [id];
    console.log('CREATE', ids);
    this.props.createJobs(ids, { ...this.getOpts(), type: pulling ? 'pulling' : 'normal'});
  }

  clean = (id, pulling = false) => {
    const ids = [id];
    console.log('CLEAN', ids);
    this.props.cleanJobs(ids, { ...this.getOpts(), type: pulling ? 'pulling' : 'normal'});
  }

  cleanMeta = (id, pulling = false) => {
    const ids = [id];
    console.log('CLEAN META', ids);
    this.props.cleanMeta(ids, { ...this.getOpts(), type: pulling ? 'pulling' : 'normal'});
  }

  remap = (id, pulling = false) => {
    const ids = [id];
    console.log('REMAP', ids);
    this.props.remapJobs(ids, { ...this.getOpts(), type: pulling ? 'pulling' : 'normal'});
  }

  cleanUp = (data) => {
    const normal = _.filter(data.normal, j => !this.isMapped(data, j) && (j.state === 'failed' || j.state === 'completed'));
    const pulling = _.filter(data.pulling, j => !this.isMapped(data, j) && (j.state === 'failed' || j.state === 'completed'));
    const ids = _.concat(normal, pulling).map(j => j.id);
    console.log('CLEAN-UP', ids);
    this.props.cleanUp(ids, { ...this.getOpts() });
  }

  remove = (data) => {
    const normal = _.filter(data.normal, j => this.isMapped(data, j));
    const pulling = _.filter(data.pulling, j => this.isMapped(data, j));
    const ids = _.concat(normal, pulling).map(j => j.id);
    console.log('REMOVE', ids);
    this.props.cleanUp(ids, { ...this.getOpts() });
  }

  cleanWebhook = (id) => {
    const ids = [id];
    console.log('CLEAN WEBHOOK', ids);
    this.props.cleanWebhook(ids, { ...this.getOpts() });
  }

  cleanDashboardsUp = () => {
    const { toCleanUp } = this.state;
    const jobIds = _(toCleanUp).map(data => {
      const normal = _.filter(data.normal, j => !this.isMapped(data, j) && (j.state === 'failed' || j.state === 'completed'));
      const pulling = _.filter(data.pulling, j => !this.isMapped(data, j) && (j.state === 'failed' || j.state === 'completed'));
      return _.concat(normal, pulling).map(j => j.id);
    }).flatten().value();

    console.log('CLEAN-UP JOB IDS', jobIds);
    this.props.cleanUp(jobIds, { ...this.getOpts() });
    this.setState({ toCleanUp: [] });
  }

  cleanDashboardsMeta = () => {
    const { toCleanMeta } = this.state;
    const dashboardIds = _(toCleanMeta).map(data => data.dashboardId).compact().uniq().value();
    console.log('CLEAN-UP JOB META IDS', dashboardIds);
    this.props.cleanMeta(dashboardIds, { ...this.getOpts(), type: 'pulling' });
    this.setState({ toCleanMeta: [] });
  }

  createDashboardsPull = () => {
    const { toCreatePull } = this.state;
    const dashboardIds = _(toCreatePull).map(data => data.dashboardId).compact().uniq().value();
    console.log('CREATE PULL JOBS', dashboardIds);
    this.props.createJobs(dashboardIds, { ...this.getOpts(), type: 'pulling' });
    this.setState({ toCreatePull: [] });
  }

  removeDashboardsJobs = () => {
    const { toRemove } = this.state;
    const jobIds = _(toRemove).map(data => {
      if (data.subscription === 'trial expired' || data.subscription === 'cancelled') {
        const normal = _.filter(data.normal, j => this.isMapped(data, j));
        const pulling = _.filter(data.pulling, j => this.isMapped(data, j));
        return _.concat(normal, pulling).map(j => j.id);
      } else {
        return [];
      }
    }).flatten().value();

    console.log('REMOVE JOBS', jobIds);
    this.props.removeJobs(jobIds, { ...this.getOpts() });
    this.setState({ toRemove: [] });
  }

  renderSelected = (type) => {
    const selected = _.find(this.state[type + 'Options'], {
      value: this.state[type],
    });
    return selected ? selected.name : '';
  };

  applyAppFilter = (app) => {
    this.setState({ app, page: 1 }, this.getStatus);
  }

  applySubsFilter = (subs) => {
    this.setState({ subs }, this.getStatus);
  }

  applyWebhookFilter = (webhook) => {
    this.setState({ webhook }, this.getStatus);
  }

  applyPullFilter = (pullJob) => {
    this.setState({ pullJob }, this.getStatus);
  }

  applyWeeklyFilter = (weeklyJob) => {
    this.setState({ weeklyJob }, this.getStatus);
  }

  applyStatusFilter = (status) => {
    this.setState({ status }, () => this.selectAllDashboards({ checked: false }));
  }

  applyActionFilter= (action) => {
    this.setState({ action }, () => this.selectAllDashboards({ checked: false }));
  }

  applyIssueFilter= (issue) => {
    this.setState({ issue }, () => this.selectAllDashboards({ checked: false }));
  }

  applyLimitFilter= (limit) => {
    this.setState({ limit }, this.getStatus);
  }

  getResults = (results) => {
    const { status, action, issue } = this.state;
    return _.map(results, r => {
      let hidden = false;
      if (status === 'HEALTHY' && r.errors && r.errors.length) hidden = true;
      if (status === 'SICK' && (!r.errors || !r.errors.length)) hidden = true;

      const actions = {};
      if (r.errors) {
        if (r.errors.indexOf('PULL JOB NOT REQUIRED') > -1) actions.CLEAN = true;
        if (r.errors.indexOf('NO WEBHOOK') > -1)  actions.RESYNC = true;
        if (r.errors.indexOf('WEEKLY JOB NOT MAPPED') > -1) actions.REMAP = true;
        if (r.errors.indexOf('PULL JOB NOT MAPPED') > -1) actions.REMAP = true;
        if (r.errors.indexOf('WEEKLY JOB NOT FOUND') > -1) actions.CREATE = true;
        if (r.errors.indexOf('PULL JOB NOT FOUND') > -1) actions.CREATE = true;
        if (r.errors.indexOf('WEEKLY META NOT FOUND') > -1) actions.REMAP = true;
        if (r.errors.indexOf('PULL META NOT FOUND') > -1) actions.REMAP = true;
        if (r.errors.indexOf('PULL JOB IS FAILED') > -1) actions.RETRY = true;
        if (r.errors.indexOf('PULL JOB IS COMPLETED') > -1) actions.RETRY = true;
        if (r.errors.indexOf('PULL JOB IS STUCK') > -1) actions.RETRY = true;
        if (r.errors.indexOf('WEEKLY JOB IS FAILED') > -1) actions.RETRY = true;
        if (r.errors.indexOf('WEEKLY JOB IS COMPLETED') > -1) actions.RETRY = true;
        if (r.errors.indexOf('WEEKLY JOB IS STUCK') > -1) actions.RETRY = true;
        if (r.errors.indexOf('MULTIPLE WEEKLY JOBS') > -1) actions.CLEAN = true;
        if (r.errors.indexOf('MULTIPLE PULL JOBS') > -1) actions.CLEAN = true;
        if (r.errors.indexOf('PULL USER IS WRONG') > -1) actions.CLEAN = true;
        if (r.errors.indexOf('WEEKLY USER IS WRONG') > -1) actions.CLEAN = true;
        if (r.errors.indexOf('WEBHOOK IS NOT REQUIRED') > -1) actions.WEBHOOK = true;
      }
      if (r.normal.length && _.some(r.normal, (j) => { return !this.isMapped(r, j) })) {
        actions.REMAP = true; actions.CLEAN = true;
      }
      if (r.pulling.length && _.some(r.pulling, (j) => { return !this.isMapped(r, j) })) {
        actions.REMAP = true; actions.CLEAN = true;
      }
      if (r.errors.indexOf('NO ACCESS') || r.errors.indexOf('NO BOARD') || r.errors.indexOf('NO USER')) {
        delete actions.CREATE;
        delete actions.RETRY;
        actions.CLEAN = true;
      }
      if (action === 'CLEAN' && !actions.CLEAN) hidden = true;
      if (action === 'RESYNC' && !actions.RESYNC) hidden = true;
      if (action === 'CREATE' && !actions.CREATE) hidden = true;
      if (action === 'REMAP' && !actions.REMAP) hidden = true;
      if (action === 'RETRY' && !actions.RETRY) hidden = true;
      if (action === 'WEBHOOK' && !actions.WEBHOOK) hidden = true;

      if (issue !== 'ALL') {
        if (!r.errors || r.errors.indexOf(issue) === -1) hidden = true;
      }

      return {
        ...r,
        hidden
      }
    });
  }

  renderPagination = () => {
    const pages = pagination(this.props.total, this.props.page, this.state.limit, 5);

    if (pages.totalPages < 2) {
      return <div></div>;
    }
    return (
      <Pagination>
        {pages.currentPage > 1 && <Pagination.First onClick={() => this.goto(1)} />}
        {pages.currentPage > 1 && <Pagination.Prev onClick={() => this.goto(pages.currentPage - 1)} />}
        {pages.pages.map((p, i) => {
          return (
            <Pagination.Item key={i} active={p === pages.currentPage} onClick={() => this.goto(p)}>
              {p}
            </Pagination.Item>
          );
        })}
        {pages.currentPage < pages.totalPages && <Pagination.Next onClick={() => this.goto(pages.currentPage + 1)} />}
        {pages.currentPage < pages.totalPages && <Pagination.Last onClick={() => this.goto(pages.totalPages)} />}
      </Pagination>
    );
  };

  printStats = (stats) => {
    return stats ? 
      `W: ${stats.waiting} | A: ${stats.active} | F: ${stats.failed} | C: ${stats.completed} | D: ${stats.delayed}` :
      '';
  }

  renderStatus = () => {
    const { results, isLoading } = this.props;
    const rows = this.getResults(results);
    return (
      <div className={cx('table-list')}>
        { !isLoading && rows && _.map(rows, (data, i) => !data.hidden && this.renderRow(data, i))}
      </div>
    )
  }

  getStatusMessage = (job) => {
    switch (job.state) {
      case 'active': return 'Job is running';
      case 'waiting': return 'Job is waiting to run';
      case 'delayed': return `Next run: ${moment(job.timestamp + job.delay).format(this.state.dateFormat)}`;
      case 'failed': return `Fail reason: ${job.failedReason}`;
      case 'completed': return 'Job is completed';
      default: return '';
    }
  }

  isMapped = (data, job) => {
    return (job.type === 'normal' && data.normalJobs[0] && job.id === data.normalJobs[0].bullJobId) ||
      (job.type === 'pulling' && data.pullingJobs[0] && job.id === data.pullingJobs[0].bullJobId);
  }

  isRequired = (data, job) => {
    return (
      (job.type === 'pulling') &&
      (data.app === 'jira' || data.app === 'azure') &&
      (data.subscription === 'active' || data.subscription === 'trial') &&
      !data.webhookId
    ) || (job.type === 'normal');
  }

  hasFailed = (data, type) => {
    return _.some(data[type], (j) => j.state === 'failed' && !this.isMapped(data, j));
  }

  hasCompleted = (data, type) => {
    return _.some(data[type], (j) => j.state === 'completed' && !this.isMapped(data, j));
  }

  selectDashboard = ({name, checked}) => {
    if (name) {
      this.setState({
        selected: {
          ...this.state.selected,
          [name]: checked
        }
      });
    }
  }

  selectAllDashboards = ({checked}) => {
    const data = this.getResults(this.props.results);
    console.log('!data', data);
    console.log('!apply filters', _.filter(data, d => !d.hidden));

    const selected = {};
    _.forEach(data, d => {
      selected[d.dashboardId] = !d.hidden && checked;
    });
    console.log('!setSelected', selected);
    console.log('!state.selected', this.state.selected);
    this.setState({ selected });
  }

  isAllDashboardsSelected = () => {
    const { selected } = this.state;
    const data = this.getResults(this.props.results);
    return !_.some(data, d => !selected[d.dashboardId] && !d.hidden);
  }

  renderActions = (data, job) => {
    const actions = {};
    const noAccess = job.failedReason && job.failedReason.indexOf('getAccountAndUser error') === 0;
    const wrongUser = data.updatedBy ? (data.updatedBy !== job.data.userId) : (data.createdBy !== job.data.userId);
    if (this.isRequired(data, job)) {
      const hasActive = _.some(data[job.type], j => j.state === 'active' || j.state === 'waiting');
      const onlyJob = data[job.type].length <= 1 && data[`${job.type}Jobs`].length <= 1;
      const isMapped = this.isMapped(data, job);
      if (!isMapped && job.state !== 'waiting' && job.state !== 'active') {
        if (job.state !== 'completed') actions.REMAP = true;
        if (!onlyJob) actions.CLEAN = true;
      }
      if (wrongUser) {
        actions.CLEAN = true;
      }
      switch(job.state) {
        case 'delayed':
          if (!hasActive) {
            actions.PROMOTE = true;
          }
          break;
        case 'failed':
          if (!hasActive) {
            actions.RETRY = true;
          }
          break;
        case 'completed': actions.CLEAN = true; break;
        default: break;
      }
    } else if (job.state !== 'waiting' && job.state !== 'active') {
      actions.CLEAN = true;
    }
    if (noAccess) {
      actions.CLEAN = true;
    }
    return _(actions)
      .keys()
      .map((k, i) => {
        return this.renderActionButton(`${k} ${ job.type === 'normal' ? 'WEEKLY' : 'PULL' } JOB`, job.id, i);
      })
      .value();
  }

  renderRow = (data, i) => {
    const { selected } = this.state;
    const noAccess = data.errors && data.errors.indexOf('NO ACCESS') > -1;
    const noBoard = data.errors && data.errors.indexOf('NO BOARD') > -1;
    const noUser = data.errors && data.errors.indexOf('NO USER') > -1;
    return (
      <Row key={i} className={cx('row-item', data.errors && data.errors.length ? 'sick' : 'healthy')}>
        <Col className={cx('col-selector')}>
          <Form.Group controlId="bulkSelect">
            <CheckBox
              name={data.dashboardId}
              id={data.dashboardId}
              checked={selected[data.dashboardId]}
              onChange={this.selectDashboard}
            />
          </Form.Group>
        </Col>
        <Col className={cx('col-one')}>
          <p><label>Dashboard Name:</label><span>{data.name}</span></p>
          <p><label>Dashboard ID:</label><span>{data.dashboardId}</span></p>
          <p><label>Subscription:</label><span>{_.upperFirst(data.subscription)}</span></p>
          <p><label>Owner:</label><span>{data.ownerId}</span></p>
          <p><label>Created by:</label><span>{data.createdBy}</span></p>
          <p><label>Created at:</label><span>{moment(data.createdAt).format(this.state.dateFormat)}</span></p>
          <p><label>Updated by:</label><span>{data.updatedBy}</span></p>
          <p><label>Updated at:</label><span>{moment(data.updatedAt).format(this.state.dateFormat)}</span></p>          
          { 
            data.boardJQL ?
            (
              <p><label>Dahsboard JQL:</label><span>{data.boardJQL}</span></p>
            ): 
            (<p></p>)
          }
          { 
            data.webhook ?
            (<p>
              <p><label>External Webhook ID:</label><span>{data.webhook.externalId}</span></p>
              <p><label>Webhook owner:</label><span>{data.userEmail}</span></p>
              <p><label>Host URL:</label><span>{data.userHost}</span></p>
              <p><label>Webhook JQL:</label><span>{data.webhook.jql}</span></p>
              <p><label>Webhook URL:</label><EllipsisWithTooltip url={data.webhook.url} /></p>
              <p><label>Webhook created at:</label><span>{moment(data.webhook.createdAt).format(this.state.dateFormat)}</span></p>
              <p><label>Last webhook update received at:</label><span>{moment(data.webhook.lastCalledAt).format(this.state.dateFormat)}</span></p>
              <p><label>Webhook prolonged At:</label><span>{moment(data.webhook.lastProlongedDate).format(this.state.dateFormat)}</span></p>              
            </p>):
            (<p></p>)
          }
          { 
            (!data.webhook && (data.webhookId || data.lastWebhookReceived)) ? 
            (<p><label>Webhook ({data.webhookId}):</label><span>{moment(data.lastWebhookReceived).format(this.state.dateFormat)}</span></p>):
            ''
          }
          { 
            (!data.webhook && !data.webhookId && !data.lastWebhookReceived) ? 
            (<p><label>Webhook:</label><span>No webhook</span></p>):''
          }
          {
            data.errors && !!data.errors.length &&
            (<p><label>Issues:</label><span>{data.errors.join(' | ')}</span></p>)
          }
        </Col>
        <Col className={cx('col-two')}>
          <Row>
            <Col>
              { 
                _.map(data.errors ? ['', ...data.errors] : [''], (e, i) => {
                  return (
                    <Fragment key={i}>
                      {
                        e === 'NO USER' && (!!data.pulling.length || !!data.normal.length) && (
                          this.renderActionButton('REMOVE JOBS', data)
                        )
                      }
                      {
                        e === 'NO ACCESS' && !noUser && (!!data.pulling.length || !!data.normal.length) && (
                          this.renderActionButton('REMOVE JOBS', data)
                        )
                      }
                      {
                        e === 'NO BOARD' && !noUser && !noAccess && (!!data.pulling.length || !!data.normal.length) && (
                          this.renderActionButton('REMOVE JOBS', data)
                        )
                      }
                      {
                        e === 'WEEKLY JOB NOT FOUND' && !noAccess && !noBoard && !noUser && (
                          this.renderActionButton('CREATE WEEKLY JOB', data.dashboardId)
                        )
                      }
                      {
                        e === 'WEEKLY JOB NOT FOUND' && (noAccess || noBoard || noUser) && (!!data.normalJobs.length) && (
                          this.renderActionButton('CLEAN WEEKLY META', data.dashboardId)
                        )
                      }
                      {
                        e === 'PULL JOB NOT FOUND' && !noAccess && !noBoard && !noUser && (
                          this.renderActionButton('CREATE PULL JOB', data.dashboardId)
                        )
                      }
                      {
                        e === 'PULL JOB NOT FOUND' && (noAccess || noBoard || noUser) && (!!data.pullingJobs.length) && (
                          this.renderActionButton('CLEAN PULL META', data.dashboardId)
                        )
                      }
                      {
                        e === 'PULL JOB NOT REQUIRED' &&
                        !!data.pullingJobs[0] &&
                        (this.renderActionButton('CLEAN PULL META', data.dashboardId))
                      }
                      {
                        e === 'WEBHOOK IS NOT REQUIRED' &&
                        (this.renderActionButton('CLEAN WEBHOOK', data.dashboardId))
                      }
                      {
                        e === '' && (
                          this.hasFailed(data, 'pulling') ||
                          this.hasFailed(data, 'normal') ||
                          this.hasCompleted(data, 'pulling') ||
                          this.hasCompleted(data, 'normal')
                        ) && (
                          this.renderActionButton('CLEAN-UP JOBS', data)
                        )
                      }
                    </Fragment>
                  )
                })

              }
            </Col>
          </Row>
          {
            _.map([...data.normal, ...data.pulling], (n, i) => {
              return (
                <Fragment key={i}>
                  { !i && <hr/> }
                  <Row >
                    <Col>
                      <div>{n.type === 'normal' ? 'WEEKLY' : 'PULLJOB'}</div>
                      <div>{n.state.toUpperCase()}</div>
                      <div>{ this.isMapped(data, n) ? 'MAPPED' : 'NOT MAPPED' }</div>
                    </Col>
                    <Col>
                      <div>{n.id}</div>
                      <div>User: {n.data.userId}</div>
                      <div>{this.getStatusMessage(n)}</div>
                      { this.isMapped(data, n) && (
                        <div>Progress: {data[`${n.type}Jobs`][0]?.progress}</div>
                      )}
                      { this.isMapped(data, n) && (
                        <div>Error: {data[`${n.type}Jobs`][0]?.error}</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {
                        this.renderActions(data, n)
                      }
                    </Col>
                  </Row>
                  <hr/>
                </Fragment>
              )
            })
          }
        </Col>
      </Row>
    );
  };

  renderActionButton = (action, id, i = 0) => {
    let text = (<p></p>);
    let confirm = '';
    let cb;
    switch (action) {
      case 'PROMOTE PULL JOB': 
      case 'PROMOTE WEEKLY JOB': 
        text = (<p>Are you sure you want to promote job?</p>);
        confirm = 'Promote';
        cb = () => this.promote(id);
        break;
      case 'RETRY PULL JOB':
      case 'RETRY WEEKLY JOB':
        text = (<p>Are you sure you want to retry job?</p>);
        confirm = 'Retry';
        cb = () => this.retry(id);
        break;
      case 'CREATE WEEKLY JOB':
        text = (<p>Are you sure you want to create job?</p>);
        confirm = 'Create weekly job';
        cb = () => this.create(id, false);
        break;
      case 'CREATE PULL JOB':
        text = (<p>Are you sure you want to create job?</p>);
        confirm = 'Create pull job';
        cb = () => this.create(id, true);
        break;
      case 'CLEAN WEEKLY JOB':
        text = (<p>Are you sure you want to clean job?</p>);
        confirm = 'Clean weekly job';
        cb = () => this.clean(id, false);
        break;
      case 'CLEAN WEEKLY META':
        text = (<p>Are you sure you want to remove meta?</p>);
        confirm = 'Clean weekly meta';
        cb = () => this.cleanMeta(id, false);
        break;
      case 'CLEAN PULL META':
        text = (<p>Are you sure you want to remove meta?</p>);
        confirm = 'Clean pull meta';
        cb = () => this.cleanMeta(id, true);
        break;
      case 'CLEAN PULL JOB':
        text = (<p>Are you sure you want to clean job?</p>);
        confirm = 'Clean pull job';
        cb = () => this.clean(id, true);
        break;
      case 'REMAP WEEKLY JOB':
        text = (<p>Are you sure you want to remap job?</p>);
        confirm = 'Remap weekly job';
        cb = () => this.remap(id, false);
        break;
      case 'REMAP PULL JOB':
        text = (<p>Are you sure you want to remap job?</p>);
        confirm = 'Remap pull job';
        cb = () => this.remap(id, true);
        break;
      case 'CLEAN-UP JOBS':
        text = (<p>Are you sure you want to clean-up jobs?</p>);
        confirm = 'Clean-up jobs';
        cb = () => this.cleanUp(id);
        break;
      case 'CLEAN WEBHOOK':
        text = (<p>Are you sure you want to remove webhook?</p>);
        confirm = 'Clean Webhook';
        cb = () => this.cleanWebhook(id);
        break;
      case 'REMOVE JOBS':
        text = (<p>Are you sure you want to remove all jobs?</p>);
        confirm = 'Remove Jobs';
        cb = () => this.remove(id);
        break;
      default: return null;
    }
    return (
      <Confirm
        key={i}
        body={text}
        confirmText={confirm}
        icon={resyncIcon}
        title={confirm}
        onConfirm={cb}
      >
        <Button className={cx('action')} style={{ padding: '10px 20px', margin: '5px', width: '120px', opacity: '1' }}>
          { confirm }
        </Button>
      </Confirm>
    );
  }

  addTo = (key) => {
    const { selected } = this.state;
    const to = this.state[key];
    const { results } = this.props;
    const toAdd = [];
    _.forEach(selected, (v, k) => {
      if (v) {
        toAdd.push(_.find(results, r => r.dashboardId === k));
      }
    });
    this.setState({
      [key]: _(toAdd).compact().concat(to).uniqBy('dashboardId').value()
    });
  }

  renderStats = () => {
    const { stats, isLoading } = this.props;
    
    const result = [];
    if (!isLoading && stats) {
      result.push((
        <div key={1} className={cx('queue-stats')}>
          <div className={cx('queue-stats-title')}>WEEKLY</div>
          <div className={cx('queue-stats-label waiting')}>{`${stats.weekly.waiting} waiting`}</div>
          <div className={cx('queue-stats-label active')}>{`${stats.weekly.active} active`}</div>
          <div className={cx('queue-stats-label completed')}>{`${stats.weekly.completed} completed`}</div>
          <div className={cx('queue-stats-label failed')}>{`${stats.weekly.failed} failed`}</div>
          <div className={cx('queue-stats-label delayed')}>{`${stats.weekly.delayed} delayed`}</div>
        </div>
      ));
      if (stats.pull) {
        result.push((
          <div key={2} className={cx('queue-stats')}>
            <div className={cx('queue-stats-title')}>PULL</div>
            <div className={cx('queue-stats-label waiting')}>{`${stats.pull.waiting} waiting`}</div>
            <div className={cx('queue-stats-label active')}>{`${stats.pull.active} active`}</div>
            <div className={cx('queue-stats-label completed')}>{`${stats.pull.completed} completed`}</div>
            <div className={cx('queue-stats-label failed')}>{`${stats.pull.failed} failed`}</div>
            <div className={cx('queue-stats-label delayed')}>{`${stats.pull.delayed} delayed`}</div>
          </div>
        ));
      }
    }

    return result;
  }

  renderControls = () => {
    const { toCleanUp, toCleanMeta, toCreatePull, toRemove } = this.state;
    return (
      <Fragment>
        <div className={cx('action-set')}>
          <Confirm
            body="Do you want to clean up dashboards?"
            confirmText="Clean up"
            icon={resyncIcon}
            title="Clean up"
            onConfirm={this.cleanDashboardsUp}
          >
            <span className={cx(`button-run ${toCleanUp.length ? 'enabled' : 'disabled'}`)}>›</span>
          </Confirm>
          <Button onClick={() => this.addTo('toCleanUp')} className={cx('action')} style={{ margin: 0, width: '170px', opacity: '1' }}>
            {`CLEAN UP (${ toCleanUp.length })`}
          </Button>
        </div>

        <div className={cx('action-set')}>
          <Confirm
            body="Do you want to clean jobs meta?"
            confirmText="Clean"
            icon={resyncIcon}
            title="Clean"
            onConfirm={this.cleanDashboardsMeta}
          >
            <span className={cx(`button-run ${toCleanMeta.length ? 'enabled' : 'disabled'}`)}>›</span>
          </Confirm>
          <Button onClick={() => this.addTo('toCleanMeta')} className={cx('action')} style={{ margin: 0, width: '170px', opacity: '1' }}>
            {`CLEAN META (${ toCleanMeta.length })`}
          </Button>
        </div>

        <div className={cx('action-set')}>
          <Confirm
            body="Do you want to create pull jobs?"
            confirmText="Create"
            icon={resyncIcon}
            title="Create"
            onConfirm={this.createDashboardsPull}
          >
            <span className={cx(`button-run ${toCreatePull.length ? 'enabled' : 'disabled'}`)}>›</span>
          </Confirm>
          <Button onClick={() => this.addTo('toCreatePull')} className={cx('action')} style={{ margin: 0, width: '170px', opacity: '1' }}>
            {`CREATE PULL (${ toCreatePull.length })`}
          </Button>
        </div>

        <div className={cx('action-set')}>
          <Confirm
            body="Do you want to remove jobs?"
            confirmText="Remove"
            icon={resyncIcon}
            title="Remove"
            onConfirm={this.removeDashboardsJobs}
          >
            <span className={cx(`button-run ${toRemove.length ? 'enabled' : 'disabled'}`)}>›</span>
          </Confirm>
          <Button onClick={() => this.addTo('toRemove')} className={cx('action')} style={{ margin: 0, width: '170px', opacity: '1' }}>
            {`REMOVE JOBS (${ toRemove.length })`}
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    const { isLoading, total } = this.props;

    return (
      <div>
        <div className={cx('jobs-stats')} id="jobs-stats">
          { this.renderStats() }
        </div>
        <hr/>
        <div className={cx('jobs-controls')} id="jobs-controls">
          { this.renderControls() }
        </div>
        <hr/>
        <div className={cx('table-content', 'status')}>
          <div className={cx('table-filter')}>
            <div className={cx('table-filter-group')}>
              <div className={cx('table-filter-filters')}>
                <Form.Group controlId="app" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('app')}
                  >
                    {this.state.appOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.app === f.value,
                          })}
                          onClick={() => this.applyAppFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Application</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="subs" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('subs')}
                  >
                    {this.state.subsOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.subs === f.value,
                          })}
                          onClick={() => this.applySubsFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Subscription</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="webhook" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('webhook')}
                  >
                    {this.state.webhookOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.webhook === f.value,
                          })}
                          onClick={() => this.applyWebhookFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Webhook</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="weekly" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('weeklyJob')}
                  >
                    {this.state.weeklyJobOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.weeklyJob === f.value,
                          })}
                          onClick={() => this.applyWeeklyFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Weekly</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="pulljob" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('pullJob')}
                  >
                    {this.state.pullJobOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.pullJob === f.value,
                          })}
                          onClick={() => this.applyPullFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Pulling</span>
                  </Form.Label>
                </Form.Group>
              </div>
              <div className={cx('table-filter-controls')}>
                <Form.Group controlId="Search" className="u-di search-form white mr-2">
                  <Form.Control
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={this.handleChange}
                    value={this.state.search}
                  />
                </Form.Group>
                <Form.Group controlId="action" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('limit')}
                  >
                    {this.state.limitOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.limit === f.value,
                          })}
                          onClick={() => this.applyLimitFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Limit</span>
                  </Form.Label>
                </Form.Group>
              </div>
            </div>
            <div className={cx('table-filter-group')}>
              <div className={cx('table-filter-filters')}>
                <Form.Group
                  controlId="bulkSelect"
                  className={cx('bulk-select d-flex align-items-center justify-content-center pr-2')}
                >
                  <CheckBox
                    name="isAllChecked"
                    id="isAllChecked"
                    checked={this.isAllDashboardsSelected()}
                    onChange={this.selectAllDashboards}
                  />
                </Form.Group>
                <Form.Group controlId="status" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('status')}
                  >
                    {this.state.statusOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.status === f.value,
                          })}
                          onClick={() => this.applyStatusFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Status</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="action" className="white filter-dropdown">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('action')}
                  >
                    {this.state.actionOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.action === f.value,
                          })}
                          onClick={() => this.applyActionFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Action</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group controlId="issue" className="white filter-dropdown filter-dropdown-wide">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('issue')}
                  >
                    {this.state.issueOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.issue === f.value,
                          })}
                          onClick={() => this.applyIssueFilter(f.value)}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Issue</span>
                  </Form.Label>
                </Form.Group>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className={cx('table-loading')}>
              <Loading />
            </div>
          )}
          {!isLoading && total === 0 && (
            <div className={cx('no_data')}>
              <p> No data available </p>
            </div>
          )}

          { this.renderStatus() }
        </div>
        { total > 0 && this.renderPagination() }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStatus: (opt) => dispatch(getStatus(opt)),
    promoteJobs: (ids, opt) => dispatch(promoteJobs(ids, opt)),
    retryJobs: (ids, opt) => dispatch(retryJobs(ids, opt)),
    createJobs: (ids, opt) => dispatch(createJobs(ids, opt)),
    cleanJobs: (ids, opt) => dispatch(cleanJobs(ids, opt)),
    remapJobs: (ids, opt) => dispatch(remapJobs(ids, opt)),
    removeJobs: (ids, opt) => dispatch(removeJobs(ids, opt)),
    cleanMeta: (ids, opt) => dispatch(cleanMeta(ids, opt)),
    cleanUp: (ids, opt) => dispatch(cleanUp(ids, opt)),
    cleanWebhook: (ids, opt) => dispatch(cleanWebhook(ids, opt)),
  };
};

function mapStateToProps(state) {
  return {
    results: state.status.results,
    stats: state.status.stats,
    isLoading: state.status.isLoading,
    page: state.status.page,
    total: state.status.total,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusTable);
