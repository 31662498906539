import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-bootstrap';

import Loading from '../../components/Loading/Loading';
import { getStats } from '../Dashboard/DashboardActions';

import styles from '../../assets/styles/_table.scss';
let cx = classNames.bind(styles);

class Students extends Component {
  componentDidMount() {
    if (!this.props.total) {
      this.props.getStats();
    }
  }

  renderRow = (contact, i) => {
    return (
      <Row key={i} className={cx('row-item')}>
        <Col lg="3">
          <p>
            <label>Email:</label> <span>{contact.email}</span>
          </p>
        </Col>

        <Col lg="3">
          <p>
            <label>Name:</label> <span>{contact.name}</span>
          </p>
        </Col>

        <Col lg="2">
          <p>
            <label>Masterclass Time:</label> <span>{contact.masterclassTime}</span>
          </p>
        </Col>

        <Col lg="2">
          <p>
            <label>Attended:</label> <span>{!!contact.attended ? 'Yes' : 'No'}</span>
          </p>
        </Col>

        <Col lg="2">
          <p>
            <label>Enrolled:</label> <span>{!!contact.enrolled ? 'Yes' : 'No'}</span>
          </p>
        </Col>
      </Row>
    );
  };

  render() {
    const { isLoading, results, total } = this.props;

    return [
      <Helmet key="0" title="Students" />,
      <Container key="1">
        <div>
          <div className={cx('table-content')}>
            <div className={cx('table-filter')}></div>

            {isLoading && (
              <div className={cx('table-loading')}>
                <Loading />
              </div>
            )}
            {!isLoading && total === 0 && (
              <div className={cx('no_data')}>
                <p> No data available </p>
              </div>
            )}
            <div className={cx('table-list')}> {!isLoading && results.map((c, i) => this.renderRow(c, i))} </div>
          </div>
        </div>
      </Container>,
    ];
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStats: () => dispatch(getStats()),
  };
};

function mapStateToProps(state) {
  return {
    isLoading: state.dashboard.isLoading,
    results: !!state.dashboard.stats && !!state.dashboard.course ? state.dashboard.stats.course.contacts : [],
    total: !!state.dashboard.stats && !!state.dashboard.course ? state.dashboard.stats.course.contacts.length : 0,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Students);
