import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames/bind';
import { Button, Form, FormGroup, Modal } from 'react-bootstrap';
import { handleError, createChatFilter, updateChatFilter } from '../ChatFilterActions';

import styles from './ChatFilter.scss';
let cx = classNames.bind(styles);

const defaultState = {
  model: {
    domains: '',
  },
};

class ChatFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      isOpened: props.visible,
    };
  }

  componentDidMount() {
    if (this.props.model) {
      this.setModel();
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.model, this.props.model)) {
      this.setModel();
    }
  }

  setModel() {
    const { model } = this.props;

    if (!model) return;

    this.setState({
      model: {
        _id: model._id,
        domains: model.domains ? model.domains.join(', ') : '',
      },
    });
  }

  onButtonClick = () => {
    if (!this.state.isOpened) {
      this.setState(
        {
          isOpened: true,
        },
        () => this.setModel()
      );
    }
  };

  onClose = (event, propagate) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({
      ...defaultState,
      isOpened: false,
    });
    !!this.props.onClose && !!propagate && this.props.onClose();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { model } = this.state;

    if (model._id) {
      this.props.updateChatFilter(this.formatModel(model));
    } else {
      this.props.createChatFilter(this.formatModel(model));
    }
    this.onClose();
  };

  formatModel = (model) => {

    const names = model.domains ? model.domains.trim() : '';
    const domains = !names ? [] : _(names.split(','))
      .map(d => d.trim())
      .uniq()
      .value();

    const result = {
      _id: model._id,
      domains: domains,
    };
    return result;
  };

  handleInput = (e) => {
    this.setState({
      model: {
        ...this.state.model,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { model, isOpened } = this.state;

    const modal = (
      <Modal
        keyboard={false}
        backdrop={'static'}
        show={isOpened}
        onHide={this.onClose}
        className={cx('chat-filter-settings-modal')}
      >
        <Modal.Header>
          <Modal.Title>Disable chat feature</Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx('form_content')}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup controlId="name" className={cx('name_input')}>
              <label className="label">Domain names</label>
              <textarea
                className="textarea"
                type="textarea"
                name="domains"
                value={model.domains}
                onChange={e => {
                  this.setState({
                    model: {
                      ...this.state.model,
                      domains: e.target.value
                    }
                  });
                }}
              />
              <label className="label-fix">Additional domains must be separated by comma</label>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className={cx('btn_blue')} type="submit" onClick={this.handleSubmit}>
            {this.props.isLoading ? 'Loading...' : model._id ? 'Update' : 'Save'}
          </Button>
          <Button className={cx('btn_white')} onClick={this.onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );

    let content;
    if (this.props.children) {
      const btn = React.Children.only(this.props.children);
      content = React.cloneElement(
        btn,
        {
          ...this.props,
          onClick: this.onButtonClick,
        },
        btn.props.children,
        modal
      );
    } else {
      content = (
        <Button onClick={this.onButtonClick} style={this.props.style}>
          {this.props.buttonText}
          {modal}
        </Button>
      );
    }

    return content;
  }
}

ChatFilter.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  model: PropTypes.object,
};

ChatFilter.defaultProps = {
  visible: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleError: (msg) => dispatch(handleError(msg)),
    updateChatFilter: (model) => dispatch(updateChatFilter(model)),
    createChatFilter: (model) => dispatch(createChatFilter(model)),
  };
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.chat.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatFilter);
