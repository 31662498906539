import React, { Component } from 'react';

export default class PublicLayout extends Component {
  render() {
    const Component = this.props.component;
    const route = this.props.route;
    return (
      <div>
        {/* <h1>Public Layout</h1> */}
        <Component route={route} />
      </div>
    );
  }
}
