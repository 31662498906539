import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';

import AccountsTable from './components/AccountsTable';
class Accounts extends Component {
  render() {
    return [
      <Helmet key="0" title="Accounts" />,
      <Container key="1">
        <AccountsTable pagination={true} />
      </Container>,
    ];
  }
}

export default Accounts;
