import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Nav } from 'react-bootstrap';

import logoNave from '../../assets/images/logo.svg';

import dashboradIcon from '../../assets/images/dashboard.svg';
import accountsIcon from '../../assets/images/accounts.svg';
import partnersIcon from '../../assets/images/parthners.svg';
import settingsIcon from '../../assets/images/settings-icon.svg';
import studentsIcon from '../../assets/images/students.svg';
import notificationsIcon from '../../assets/images/notifications.svg';
// import reportsIcon from '../../assets/images/reports.svg';

import styles from './Navigation.scss';
let cx = classNames.bind(styles);

class Navigation extends Component {
  closeMenu = () => {
    document.body.classList.remove('menu-shown');
  };
  render() {
    const { className } = this.props;

    return (
      <div className={cx('nav-custom', className)}>
        <Nav className={cx('flex-column')}>
          <Nav.Item className={cx('nav_logo')} onClick={this.closeMenu}>
            <img src={logoNave} alt="#" />
          </Nav.Item>
          <Nav.Item onClick={this.closeMenu}>
            <NavLink exact to="/">
              <img className={cx('dashboradIcon')} src={dashboradIcon} alt="#" />
              <span>Dashboard</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item onClick={this.closeMenu}>
            <NavLink to="/accounts">
              <img className={cx('accountsIcon')} src={accountsIcon} alt="#" />
              <span>Accounts</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item onClick={this.closeMenu}>
            <NavLink to="/students">
              <img className={cx('studentsIcon')} src={studentsIcon} alt="#" />
              <span>Students</span>
            </NavLink>
          </Nav.Item>
          {this.props.user.partner && this.props.user.partner.role !== 'partner' && (
            <Nav.Item onClick={this.closeMenu}>
              <NavLink to="/partners">
                <img className={cx('partnersIcon')} src={partnersIcon} alt="#" />
                <span>Partners</span>
              </NavLink>
            </Nav.Item>
          )}
          {this.props.user.partner && this.props.user.partner.role === 'owner' && this.props.user.role === 'admin' && (
            <Nav.Item onClick={this.closeMenu}>
              <NavLink to="/status">
                <img className={cx('settingsIcon')} src={settingsIcon} alt="#" />
                <span>Maintenance</span>
              </NavLink>
            </Nav.Item>
          )}
          {this.props.user.partner && this.props.user.partner.role === 'owner' && this.props.user.role === 'admin' && (
            <Nav.Item onClick={this.closeMenu}>
              <NavLink to="/notifications">
                <img className={cx('notificationsIcon')} src={notificationsIcon} alt="#" />
                <span>Notifications</span>
              </NavLink>
            </Nav.Item>
          )}
          {/* this.props.user.partner && this.props.user.partner.role !== 'partner' && <Nav.Item><NavLink to='/reports'><img className={cx('reportsIcon')} src={reportsIcon} alt="#"/><span>Reports</span></NavLink></Nav.Item> }*/}
        </Nav>
      </div>
    );
  }
}

Navigation.propTypes = {
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Navigation);
