import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Pagination, Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';

import Loading from '../../../components/Loading/Loading';
import pagination from '../../../utils/pagination';
import throttle from '../../../utils/throttle';
import { getPartners, deletePartner } from '../PartnersActions';

import settingIcon from '../../../assets/images/settings-gray-icon.svg';
import filterImage from '../../../assets/images/filter-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';

import styles from '../../../assets/styles/_table.scss';
let cx = classNames.bind(styles);

class PartnersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFormat: 'DD-MM-YYYY',
      sort: '-createdAt',
      search: '',
      sortOptions: [
        {
          name: 'Name: A to Z',
          value: 'name',
        },
        {
          name: 'Name: Z to A',
          value: '-name',
        },
        {
          name: 'Email: A to Z',
          value: 'email',
        },
        {
          name: 'Email: Z to A',
          value: '-email',
        },
        {
          name: 'Created at: newest first',
          value: '-createdAt',
        },
        {
          name: 'Created at: oldest first',
          value: 'createdAt',
        },
        {
          name: 'No of accounts: highest first',
          value: '-accounts',
        },
        {
          name: 'No of accounts: lowest first',
          value: 'accounts',
        },
      ],
    };

    this.getPartnersThrottled = throttle(this.getPartners, 500, this);
  }

  componentDidMount() {
    this.getPartners();
  }

  handleChange = (ev) => {
    this.setState({ search: ev.target.value });
    this.getPartnersThrottled();
  };

  toggleFilter = () => {
    document.getElementById('filter-content').classList.toggle('shown');
  };

  applySort = (sort) => {
    this.setState({ sort }, this.getPartners);
  };

  goto = (page = 1) => {
    if (page < 1) {
      page = 1;
    }

    this.getPartners(page);
  };

  getPartners = (page) => {
    const { sort, search } = this.state;
    this.props.getPartners({
      sort,
      search,
      page,
    });
  };

  delete = (partnerId) => {
    const { sort, search } = this.state;
    this.props.deletePartner(partnerId, {
      sort,
      search,
    });
  };

  renderPagination = () => {
    const pages = pagination(this.props.total, this.props.page, 20, 5);

    if (pages.totalPages < 2) {
      return <div></div>;
    }
    return (
      <Pagination>
        {pages.currentPage > 1 && <Pagination.First onClick={() => this.goto(1)} />}
        {pages.currentPage > 1 && <Pagination.Prev onClick={() => this.goto(pages.currentPage - 1)} />}
        {pages.pages.map((p, i) => {
          return (
            <Pagination.Item key={i} active={p === pages.currentPage} onClick={() => this.goto(p)}>
              {p}
            </Pagination.Item>
          );
        })}
        {pages.currentPage < pages.totalPages && <Pagination.Next onClick={() => this.goto(pages.currentPage + 1)} />}
        {pages.currentPage < pages.totalPages && <Pagination.Last onClick={() => this.goto(pages.totalPages)} />}
      </Pagination>
    );
  };

  renderRow = (partner, i) => {
    return (
      <Row key={i} className={cx('row-item')}>
        <Col lg="4">
          <p>
            <label>Email:</label> <span>{partner.email}</span>
          </p>
        </Col>

        <Col lg="3">
          <p>
            <label>Name:</label> <span>{partner.name}</span>
          </p>
        </Col>

        <Col lg="2">
          <p>
            <label>Referral No:</label> <span>{partner.refId}</span>
          </p>
        </Col>

        {/* <Col>
            <p><label>Total Revenue:</label> <span>$0</span></p>
          </Col> */}

        {this.props.user.partner && this.props.user.partner.role === 'owner' ? (
          <Col lg="2">
            <p>
              <label>Active Accounts:</label> <span>{partner.activeAccounts}</span>
            </p>
          </Col>
        ) : (
          <Col lg="2">
            <p>
              <label>Accounts:</label> <span>{partner.accounts}</span>
            </p>
          </Col>
        )}

        {this.props.user.partner && this.props.user.partner.role === 'owner' && (
          <Col className={cx('col-five')}>
            <Dropdown className="dropdown-settings-menu accounts">
              <Dropdown.Toggle className="btn-menu">
                <img src={settingIcon} alt="#" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={`https://portal.getnave.com/auth/remote/${partner.userId}`} target="_blank">
                  Remote login
                </Dropdown.Item>
                {this.props.user.partner.id !== partner._id && (
                  <Dropdown.Item>
                    <Confirm
                      body={
                        <p>
                          Are you sure you want to delete <br />
                          this partner?
                        </p>
                      }
                      confirmText="Delete"
                      icon={deleteIcon}
                      title="Delete partner"
                      onConfirm={() => this.delete(partner._id)}
                    >
                      <Button disabled={partner.isDeleting}>Delete partner</Button>
                    </Confirm>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>
    );
  };

  renderSelected = (type) => {
    const selected = _.find(this.state[type + 'Options'], {
      value: this.state[type],
    });
    return selected ? selected.name : '';
  };

  render() {
    const { isLoading, results, total } = this.props;

    return (
      <div>
        <div className={cx('table-content')}>
          <div className={cx('table-filter')}>
            <div id="filter-content" className={cx('filter-content')}>
              <Form.Group controlId="sort" className="white filter-dropdown">
                <DropdownButton
                  className="form-dropdown width-235"
                  id="dropdown-item-button"
                  title={this.renderSelected('sort')}
                >
                  {this.state.sortOptions.map((f, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        as="button"
                        className={cx({
                          active: this.state.platform === f.value,
                        })}
                        onClick={() => this.applySort(f.value)}
                      >
                        {f.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <Form.Label>
                  <span>Sort</span>
                </Form.Label>
              </Form.Group>
            </div>
            <Form.Group controlId="Search" className="u-di search-form white">
              <Form.Control
                type="text"
                name="search"
                placeholder="Search"
                onChange={this.handleChange}
                value={this.state.search}
              />
            </Form.Group>
            <div className={cx('filter-button')} onClick={this.toggleFilter}>
              <img alt="filter" src={filterImage} />
            </div>
          </div>

          {isLoading && (
            <div className={cx('table-loading')}>
              <Loading />
            </div>
          )}
          {!isLoading && total === 0 && (
            <div className={cx('no_data')}>
              <p> No data available </p>
            </div>
          )}
          <div className={cx('table-list')}> {!isLoading && results.map((p, i) => this.renderRow(p, i))} </div>
        </div>
        {total > 0 && this.renderPagination()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPartners: (opt) => dispatch(getPartners(opt)),
    deletePartner: (id) => dispatch(deletePartner(id)),
  };
};

function mapStateToProps(state) {
  return {
    results: state.partners.results,
    isLoading: state.partners.isLoading,
    page: state.partners.page,
    total: state.partners.total,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersTable);
