import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Button, Modal, Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';

import { createPartner } from '../../PartnersActions';
import ImageSize from '../../../../components/ImageSize/ImageSize';

import profileImage from '../../../../assets/images/default-image.svg';
import styles from './AddPartnerModal.scss';
let cx = classNames.bind(styles);

class AddPartnerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeOptions: [
        {
          name: 'Partner',
          value: 'partner',
        },
      ],
      isOpened: props.visible,
      copied: false,
      email: '',
      name: '',
      company: '',
      refId: '',
      type: 'partner',
      image: undefined,
    };
  }

  resetState = () => {
    this.setState({
      isOpened: false,
      copied: false,
      email: '',
      name: '',
      company: '',
      refId: '',
      type: 'partner',
      image: undefined,
    });
  };

  onButtonClick = () => {
    if (!this.state.isOpened) {
      this.setState({ isOpened: true });
    }
  };

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleFileUpload = (ev) => {
    this.setState({
      imageUrl: ev.target.files.length ? URL.createObjectURL(ev.target.files[0]) : undefined,
      image: ev.target.files.length ? ev.target.files[0] : undefined,
    });
  };

  onClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.resetState();

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  onConfirm = (event) => {
    event.stopPropagation();

    const model = {
      email: this.state.email,
      name: this.state.name,
      company: this.state.company,
      refId: this.state.refId,
      type: this.state.type,
      image: this.state.image,
    };

    this.props
      .createPartner(model)
      .then(() => {
        this.resetState();
      })
      .catch(() => {});
  };

  triggerInputFile = () => {
    this.fileInput.click();
  };

  renderSelected = (type) => {
    const selected = _.find(this.state[type + 'Options'], {
      value: this.state[type],
    });
    return selected ? selected.name : '';
  };

  render() {
    var modal = (
      <Modal
        show={this.state.isOpened}
        onHide={this.onClose}
        className={cx('partner_modal')}
        keyboard={this.props.keyboard}
        backdrop={this.props.backdrop}
        enforceFocus={this.props.enforceFocus}
      >
        <Modal.Header>
          <Modal.Title>
            <div className={cx('avatar')} onClick={this.triggerInputFile}>
              <input
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                ref={(fileInput) => (this.fileInput = fileInput)}
                onChange={this.handleFileUpload}
              />
              <div className={cx('images')}>
                <ImageSize alt="profile" src={this.state.imageUrl || profileImage} />
              </div>
              <span className={cx('label_upload_photo')}>Upload photo</span>
            </div>
            <h5>Add partner</h5>
            <Button variant="clear" onClick={this.onClose}></Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.user.partner && this.props.user.partner.role === 'owner' && (
            <Row>
              <Col>
                <Form.Group controlId="platform">
                  <DropdownButton
                    className="form-dropdown"
                    id="dropdown-item-button"
                    title={this.renderSelected('type')}
                  >
                    {this.state.typeOptions.map((f, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          as="button"
                          className={cx({
                            active: this.state.platform === f.value,
                          })}
                          onClick={() => this.setState({ type: f.value })}
                        >
                          {f.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Label>
                    <span>Type</span>
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Form.Group>
                <Form.Control
                  name="refId"
                  type="text"
                  id="refId"
                  placeholder="AKT number"
                  value={this.state.refId}
                  onChange={this.handleChange}
                />
                <Form.Label htmlFor="refId">
                  <span>AKT number</span>
                </Form.Label>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Group>
                <Form.Control
                  name="company"
                  type="text"
                  id="companyName"
                  placeholder="Company Name"
                  value={this.state.company}
                  onChange={this.handleChange}
                />
                <Form.Label htmlFor="companyName">
                  <span>Company Name</span>
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Form.Group>
                <Form.Control
                  name="name"
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <Form.Label htmlFor="name">
                  <span>Name</span>
                </Form.Label>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Group>
                <Form.Control
                  name="email"
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Label htmlFor="email">
                  <span>Email</span>
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="blue" size="sm" onClick={this.onConfirm}>
            {this.props.confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );

    var content;
    if (this.props.children) {
      var btn = React.Children.only(this.props.children);
      content = React.cloneElement(
        btn,
        {
          onClick: this.onButtonClick,
          style: this.props.style,
        },
        btn.props.children,
        modal
      );
    } else {
      content = (
        <Button onClick={this.onButtonClick} style={this.props.style}>
          {this.props.buttonText}
          {modal}
        </Button>
      );
    }
    return content;
  }
}

AddPartnerModal.propTypes = {
  buttonText: PropTypes.node,
  confirmText: PropTypes.node,
  keyboard: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  enforceFocus: PropTypes.bool,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

AddPartnerModal.defaultProps = {
  confirmText: 'Create partner',
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPartner: (model) => dispatch(createPartner(model)),
  };
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerModal);
