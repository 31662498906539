const messages = {
  'error.email.empty': 'Please enter your email address',
  'error.email.any.empty': 'Please enter your email address',
  'error.email.any.required': 'Please enter your email address',
  'error.email.string.email': 'There might be a typo in your email address.Please double-check',
  'error.password.empty': 'Please enter your password',
  'error.password.string.min': 'You need a stronger password that’s longer than 8 symbols',
  'error.password.string.max':
    'It’s great you’re taking online security seriously, but your password needs shorter than 99 symbols',
  'error.oldPassword.any.required': 'Please enter your old password',
  'error.oldPassword.invalid': 'Old password seems to be incorrect',
  'error.newPassword.any.required': 'Please enter your new password',
  'error.newPassword.string.min': 'You need a stronger password that’s longer than 8 symbols',
  'error.newPassword.string.max':
    'It’s great you’re taking online security seriously, but your password needs shorter than 99 symbols',
  'error.user.notfound': 'We don’t have on you on file. Are you sure you’ve signed up with this email address?',
  'error.user.invalid': 'Еmail address or password seems to be incorrect',
  'error.user.emailExists': 'An existing user is already using that email address',
  'error.token.notactivated':
    'Your account hasn’t been made active yet. Please confirm your email address in order to continue',
  'error.token.invalid': 'Your token is incorrect',
  'error.token.expired': 'Provided token is incorrect or has been expired',

  'error.refId.any.empty': 'Please enter AKT number',
  'error.name.any.empty': 'Please enter Name',
  'error.name.any.required': 'Please enter Name',
  'error.company.any.empty': 'Please enter Company Name',
  'error.partner.emailExists': 'An existing partner is already using that email address',
  'error.partner.nameExists': 'An existing partner is already using that name',
  'error.partner.refIdExists': 'An existing partner is already using that AKT number',

  'success.auth.activated': 'Your account is now active',
  'success.auth.forgotPasswordSent': 'We’ve emailed your new password. Please check your inbox.',
  'success.auth.passwordSuccesfullyReset': 'Password successfully reset',
  'success.auth.passwordSuccesfullyChanged': 'Password successfully changed',
  'success.auth.infoSuccesfullySaved': 'Account info successfully changed',
  'success.users.userAddedSuccessfully': 'New user successfully added',
  'success.users.userDeletedSuccessfully': 'User successfully removed',
};

export function formatMessage(key) {
  const message = messages[key];
  return message || key;
}

export default {
  formatMessage,
};
