import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../assets/images/copy-gray-icon.svg';

class EllipsisWithTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleCopy = () => {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000); // Hide tooltip after 2 seconds
  }; 

  render() {
    const { url } = this.props;
    const { copied } = this.state;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="url-tooltip">{url}</Tooltip>}>
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '350px', // Adjust max-width as needed
              display: 'inline-block',
              cursor: 'pointer',
            }}
          >
            {url}
          </span>
        </OverlayTrigger>
        <CopyToClipboard text={url} >
          <OverlayTrigger
            key={copied ? 'copied' : 'copy'}
            placement="top"
            overlay={<Tooltip id="copied-tooltip">{this.state.copied ? 'Copied' : 'Copy to clipboard'}</Tooltip>}
          >
            <Button
              style={{
                minWidth: '40px',
                height: '40px',
                padding: 0,
                opacity: 0.8,
                border: 0,
                color: '#555',
              }}
              onClick={this.handleCopy}
            >
              <img src={copyIcon} alt="#" style={{ width: '15px' }} />
            </Button>
          </OverlayTrigger>
        </CopyToClipboard>
      </div>
    );
  }
}
export default EllipsisWithTooltip;
