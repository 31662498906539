import { IS_LOADING, IS_ADDING, LIST } from './UsersActions';

const INITIAL_STATE = {
  isLoading: false,
  isAdding: false,
  list: [],
};

const UsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_ADDING: {
      return {
        ...state,
        isAdding: action.payload,
      };
    }
    case IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case LIST:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default UsersReducer;
