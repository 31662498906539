import _ from 'lodash';
import apiCaller from '../../utils/apiCaller';
import urlHelper from '../../utils/url';
import errorHandler from '../Common/ErrorHandler';

function formatErrors(data) {
  return _.map(data, d => `${d.dashboardId}: ${d.error}`).join('\n');
}

// Export Constants
export const STATUS_LOADING = 'STATUS_LOADING',
  STATUS_UPDATE = 'STATUS_UPDATE',
  STATUS_LIST = 'STATUS_LIST';

export function getStatus(opt = {}) {
  return (dispatch) => {
    dispatch({ type: STATUS_LOADING });

    let url = `status/list`;
    url += urlHelper.buildQuery(opt);

    apiCaller
      .api(url, 'get')
      .then((res) => {
        dispatch({
          type: STATUS_LIST,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function promoteJobs(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    apiCaller
      .api(`status/${app}/promote/${param}`, 'put')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Promoted successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to promote: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function retryJobs(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    if (!param) {
      return dispatch(errorHandler.setSuccessMessage(`Nothing to retry`));
    }
    apiCaller
      .api(`status/${app}/retry/${param}`, 'put')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Retried successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to retry: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function createJobs(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    const type = opt.type;
    if (!param) {
      return dispatch(errorHandler.setSuccessMessage(`Nothing to create`));
    }
    apiCaller
      .api(`status/${app}/create/${type}/${param}`, 'put')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Created successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to create:\n${formatErrors(data.failed)}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function cleanJobs(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    const type = opt.type;
    apiCaller
      .api(`status/${app}/clean/${type}/${param}`, 'delete')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Deleted successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to delete: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
        
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function remapJobs(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    const type = opt.type;
    apiCaller
      .api(`status/${app}/remap/${type}/${param}`, 'put')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Mapped successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to map: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function cleanMeta(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    const type = opt.type;
    if (!param) {
      return dispatch(errorHandler.setSuccessMessage(`Nothing to clean`));
    }
    apiCaller
      .api(`status/${app}/meta/${type}/${param}`, 'delete')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Deleted mapping successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to delete mapping: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function cleanWebhook(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    if (!param) {
      return dispatch(errorHandler.setSuccessMessage(`Nothing to clean`));
    }
    apiCaller
      .api(`status/${app}/webhook/${param}`, 'delete')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Deleted webhooks successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to delete webhook: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function cleanUp(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    if (!param) {
      return dispatch(errorHandler.setSuccessMessage(`Nothing to clean`));
    }
    apiCaller
      .api(`status/${app}/cleanup/${param}`, 'delete')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Cleaned successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to clean: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function removeJobs(ids, opt = {}) {
  return (dispatch) => {
    const param = _.join(ids);
    const app = opt.app;
    if (!param) {
      return dispatch(errorHandler.setSuccessMessage(`Nothing to remove`));
    }
    apiCaller
      .api(`status/${app}/remove/${param}`, 'delete')
      .then((data) => {
        if (data && data.success && data.success.length) {
          dispatch(errorHandler.setSuccessMessage(`Removed successfully: ${data.success.length}`));
        }
        if (data && data.failed && data.failed.length) {
          dispatch(errorHandler.setError(`Failed to remove: ${data.failed.length}`));
        }
        if (data && data.updated) {
          dispatch({
            type: STATUS_UPDATE,
            payload: data.updated,
          });
        } else {
          dispatch(getStatus(opt));
        }
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}
