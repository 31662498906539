import logger from '../../../utils/logger';
import intl from '../../../utils/intl';

// Export Constants
export const SET_ERROR = 'ERR_HANDLER_SET_ERROR';
export const SET_SUCCESS = 'ERR_HANDLER_SET_SUCCESS';
export const SET_INFO = 'ERR_HANDLER_SET_INFO';
export const CLEAN = 'ERR_HANDLER_CLEAN';

const DEFAULT_SETTINGS = { log: true, show: true };
// Export Actions
export function setError(err, opt) {
  opt = {
    ...DEFAULT_SETTINGS,
    ...opt,
  };

  if (!err) {
    return clean();
  }

  let message = err;
  if (err.data) {
    message = '';
    Object.keys(err.data).map((key, index) => {
      if (!err.data[key].msg) {
        return true;
      }
      message += intl.formatMessage(err.data[key].msg) + '\r\n';

      return true;
    });

    if (!message) {
      message = err;
    }
  }

  if (err instanceof Error) {
    message = err.toString();
  }

  if (opt.log) {
    logger.error(message);
  }

  if (!opt.show) {
    return { type: CLEAN };
  }

  return {
    type: SET_ERROR,
    error: message,
  };
}

export function setSuccessMessage(msgObj) {
  let msg = msgObj;
  if (msgObj.data) {
    msg = '';
    Object.keys(msgObj.data).map((key, index) => {
      if (!msgObj.data[key].msg) {
        return true;
      }
      msg += intl.formatMessage(msgObj.data[key].msg) + '\r\n';

      return true;
    });
  }

  return {
    type: SET_SUCCESS,
    msg,
  };
}

export function setInfoMessage(msgObj) {
  let msg = msgObj;
  if (msgObj.data) {
    msg = '';
    Object.keys(msgObj.data).map((key, index) => {
      if (!msgObj.data[key].msg) {
        return true;
      }
      msg += intl.formatMessage(msgObj.data[key].msg) + '\r\n';

      return true;
    });
  }

  return {
    type: SET_INFO,
    msg,
  };
}

export function clean() {
  return { type: CLEAN };
}
