import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames/bind';
import { Button, Form, FormGroup, FormControl, Modal, Col, Row } from 'react-bootstrap';
import CheckBox from '../../../components/CheckBox/CheckBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { handleError, createNotification, updateNotification } from '../NotificationsActions';

import styles from './NotificationConfig.scss';
let cx = classNames.bind(styles);

const defaultState = {
  value: {
    from: moment()
      .add(1, 'hour')
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .format('DD MMM YYYY HH:mm'),
    to: moment()
      .add(1, 'hour')
      .add(1, 'week')
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .format('DD MMM YYYY HH:mm'),
  },
  model: {
    name: '',
    description: '',
    enabled: false,
    audience: {},
    timeline: {},
    start_day: 0,
    end_day: 0,
    fixed_period: true,
    rolling_period: false,
  },
};

class NotificationConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      isOpened: props.visible,
    };
  }

  componentDidMount() {
    if (this.props.model) {
      this.setModel();
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.model, this.props.model)) {
      this.setModel();
    }
  }

  setModel() {
    const { model } = this.props;

    if (!model) return;

    this.setState({
      value: {
        from: moment(model.from).format('DD MMM YYYY HH:mm'),
        to: moment(model.to).format('DD MMM YYYY HH:mm'),
        start_day: model.start_day || 0,
        end_day: model.end_day || 0,
      },
      model: {
        _id: model._id,
        name: model.name,
        description: model.description,
        audience: { ...model.audience },
        timeline: { ...model.timeline },
        enabled: model.enabled,
        fixed_period: model.fixed_period,
        rolling_period: model.rolling_period,
      },
    });
  }

  getAudience(id) {
    switch (id) {
      case '1':
        return 'Has billing access';
      case '2':
        return 'Has monthly plan';
      case '3':
        return 'Has annual plan';
      case '4':
        return 'Trials';
      case '5':
        return 'Enrolled in SP';
      case '6': 
        return 'Has actual billing plan';
      default:
        return 'All users';
    }
  }

  getTimeline(id) {
    switch (id) {
      case '1':
        return 'Trial';
      case '2':
        return 'Activated';
      default:
        return '';
    }
  }

  onButtonClick = () => {
    if (!this.state.isOpened) {
      this.setState(
        {
          isOpened: true,
        },
        () => this.setModel()
      );
    }
  };

  onClose = (event, propagate) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({
      ...defaultState,
      isOpened: false,
    });
    !!this.props.onClose && !!propagate && this.props.onClose();
  };

  handleCheck = (e) => {
    const [type, id] = e.name.split('-');

    this.setState({
      model: {
        ...this.state.model,
        audience: {
          ...this.state.model.audience,
          [id]: e.checked ? { include: true, exclude: false }[type] : undefined,
        },
      },
    });
  };

  handleTimelineSelection = (e) => {
    const stateModel = this.state.model;

    if (e.target.name === 'trial') {
      this.setState({
        model: {
          ...stateModel,
          timeline: {
            trial: !stateModel.timeline?.['trial'],
            activated: false,
          },
        },
      });
    }

    if (e.target.name === 'activated') {
      this.setState({
        model: {
          ...stateModel,
          timeline: {
            activated: !stateModel.timeline?.['activated'],
            trial: false,
          },
        },
      });
    }
  };

  handlePeriodSelection = (e) => {
    if (e.target.name === 'fixed_period') {
      this.setState({
        model: {
          ...this.state.model,
          fixed_period: !this.state.model['fixed_period'],
          rolling_period: false,
        },
      });
    }

    if (e.target.name === 'rolling_period') {
      this.setState({
        model: {
          ...this.state.model,
          rolling_period: !this.state.model['rolling_period'],
          fixed_period: false,
        },
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { model, value } = this.state;

    if (!model.name) {
      this.props.handleError('Specify name of the notification');
      return false;
    }
    if (!model.fixed_period && !model.rolling_period) {
      this.props.handleError('Enter a period type (Either fixed or rolling period)');
      return false;
    }
    if (!model.description) {
      this.props.handleError('Specify notification text');
      return false;
    }

    if (model?.rolling_period) {
      if (!value.start_day && !value.end_day) {
        this.props.handleError('Date format is not valid');
        return false;
      }

      if (parseInt(value.end_day) < parseInt(value.start_day)) {
        this.props.handleError('Start date should be greater than end date');
        return false;
      }

      model.from = moment().valueOf();
      model.to = undefined;
      model.start_day = value.start_day;
      model.end_day = value.end_day;
      model.audience = {};
    }

    if (model?.fixed_period) {
      if (value.from === 'Invalid date' || value.to === 'Invalid date') {
        this.props.handleError('Date time format is not valid');
        return false;
      }
      const hasEnabledAudience = !!Object.values(model.audience).find((c) => c === true);

      if (!hasEnabledAudience) {
        this.props.handleError('Select at least one audience');
        return false;
      }

      model.from = moment(value.from).valueOf();
      model.to = moment(value.to).valueOf();
    }

    if (model._id) {
      this.props.updateNotification(this.formatModel(model));
    } else {
      this.props.createNotification(this.formatModel(model));
    }
    this.onClose();
  };

  formatModel = (model) => {
    const result = {
      _id: model._id,
      name: model.name,
      description: model.description,
      from: model.from,
      to: model.to,
      enabled: true,
      audience: model.audience,
      timeline: model.timeline,
      fixed_period: model.fixed_period,
      rolling_period: model.rolling_period,
      start_day: model.start_day,
      end_day: model.end_day,
    };
    return result;
  };

  handleInput = (e) => {
    this.setState({
      model: {
        ...this.state.model,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleRichTextInput = (value) => {
    this.setState({
      model: {
        ...this.state.model,
        description: value,
      },
    });
  };

  handleDateInput = (e) => {
    this.setState({
      value: {
        ...this.state.value,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleDateInputOut = (e) => {
    if (moment(e.target.value).valueOf() === 'Invalid date') {
      this.setState({
        value: {
          ...this.state.value,
          [e.target.name]: 'Invalid date',
        },
      });
    } else {
      this.setState({
        value: {
          ...this.state.value,
          [e.target.name]: moment(e.target.value).format('DD MMM YYYY HH:mm'),
        },
      });
    }
  };

  render() {
    const modulesQuill = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ['bold', 'italic', 'underline'],
          ['link'],
          ['clean'],
        ],
      },
    };

    const formatsQuill = [
      'header',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'indent',
      'align',
      'link',
      'image',
      'clean',
    ];

    const { model, isOpened, value } = this.state;

    const modal = (
      <Modal
        keyboard={false}
        backdrop={'static'}
        show={isOpened}
        onHide={this.onClose}
        className={cx('notification-settings-modal')}
      >
        <Modal.Header>
          <Modal.Title>Notification settings</Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx('form_content')}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup controlId="name" className={cx('name_input')}>
              <FormControl type="text" name="name" value={model.name} autoComplete="off" onChange={this.handleInput} />
              <label className="label-fix">Notification name</label>
            </FormGroup>

            <FormGroup controlId="description" className={cx('description_input')}>
              <ReactQuill
                theme="snow"
                value={model.description}
                onChange={this.handleRichTextInput}
                modules={modulesQuill}
                formats={formatsQuill}
              />
              <label className="label-fix">Notification text</label>
            </FormGroup>

            <Row>
              <Col sm={6}>
                <FormGroup controlId="fixed-period">
                  <Form.Check
                    type="radio"
                    name="fixed_period"
                    id="fixed-period-input"
                    label="Fixed Period"
                    onClick={this.handlePeriodSelection}
                    checked={model.fixed_period}
                    className={cx('checkbox-period-radio')}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup controlId="rolling-period">
                  <Form.Check
                    type="radio"
                    name="rolling_period"
                    id="rolling-period-input"
                    checked={model.rolling_period}
                    onClick={this.handlePeriodSelection}
                    label="Rolling Period"
                    className={cx('checkbox-period-radio')}
                  />
                </FormGroup>
              </Col>
            </Row>

            {model.fixed_period && (
              <Row className={cx('select_date_range')}>
                <Col sm={6}>
                  <FormGroup controlId="range-start" className={cx('range_start_input')}>
                    <FormControl
                      type="text"
                      name="from"
                      value={value.from}
                      autoComplete="off"
                      onChange={this.handleDateInput}
                      onBlur={this.handleDateInputOut}
                    />
                    <label className="label-fix">Starts on</label>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup controlId="range-end" className={cx('range_end_input')}>
                    <FormControl
                      type="text"
                      name="to"
                      value={value.to}
                      autoComplete="off"
                      onChange={this.handleDateInput}
                      onBlur={this.handleDateInputOut}
                    />
                    <label className="label-fix">Ends on</label>
                  </FormGroup>
                </Col>
              </Row>
            )}

            {model.rolling_period && (
              <Row className={cx('select_date_range')}>
                <Col sm={6}>
                  <FormGroup controlId="range-start-day" className={cx('range_start_input')}>
                    <FormControl
                      type="number"
                      name="start_day"
                      min="0"
                      value={value.start_day}
                      autoComplete="off"
                      onChange={this.handleDateInput}
                    />
                    <label className="label-fix">Starts on day</label>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup controlId="range-end" className={cx('range_end_input')}>
                    <FormControl
                      type="number"
                      min="0"
                      name="end_day"
                      value={value.end_day}
                      autoComplete="off"
                      onChange={this.handleDateInput}
                    />
                    <label className="label-fix">Ends on day</label>
                  </FormGroup>
                </Col>
              </Row>
            )}

            {model.rolling_period && (
              <Row>
                <Col sm={6}>
                  <FormGroup controlId="include-timeline" className={cx('include_timeline')}>
                    <div className={cx('label')}>Timeline</div>
                    {['Trial', 'Activated'].map((val) => (
                      <FormGroup controlId={`include-timeline-${val}`} key={val}>
                        <Form.Check
                          type="radio"
                          id={val.toLowerCase()}
                          name={val.toLowerCase()}
                          label={val}
                          onClick={this.handleTimelineSelection}
                          checked={model?.timeline?.[val.toLowerCase()] === true}
                          className={cx('checkbox-period-radio')}
                        />
                      </FormGroup>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            )}

            {model.fixed_period && (
              <Row>
                <Col sm={6}>
                  <FormGroup controlId="include-audience">
                    <div className={cx('label')}>Include audience</div>
                    {_.range(1, 7).map((v) => (
                      <CheckBox
                        key={v.toString()}
                        id={`include-${v}`}
                        name={`include-${v}`}
                        label={this.getAudience(v.toString())}
                        checked={model.audience[v.toString()] === true}
                        onChange={this.handleCheck}
                      />
                    ))}
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup controlId="exclude-audience">
                    <div className={cx('label')}>Exclude audience</div>
                    {_.range(1, 7).map((v) => (
                      <CheckBox
                        key={v.toString()}
                        id={`exclude-${v}`}
                        name={`exclude-${v}`}
                        label={this.getAudience(v.toString())}
                        checked={model.audience[v.toString()] === false}
                        onChange={this.handleCheck}
                      />
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className={cx('btn_white')} onClick={this.onClose}>
            Close
          </Button>
          <Button className={cx('btn_blue')} type="submit" onClick={this.handleSubmit}>
            {this.props.isLoading ? 'Loading...' : model._id ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    );

    let content;
    if (this.props.children) {
      const btn = React.Children.only(this.props.children);
      content = React.cloneElement(
        btn,
        {
          ...this.props,
          onClick: this.onButtonClick,
        },
        btn.props.children,
        modal
      );
    } else {
      content = (
        <Button onClick={this.onButtonClick} style={this.props.style}>
          {this.props.buttonText}
          {modal}
        </Button>
      );
    }

    return content;
  }
}

NotificationConfig.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  model: PropTypes.object,
};

NotificationConfig.defaultProps = {
  visible: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleError: (msg) => dispatch(handleError(msg)),
    createNotification: (model) => dispatch(createNotification(model)),
    updateNotification: (model) => dispatch(updateNotification(model)),
  };
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.notifications.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationConfig);
