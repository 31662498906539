import history from '../../utils/history';
import apiCaller from '../../utils/apiCaller';
import storage from '../../utils/storage';
import errorHandler from '../Common/ErrorHandler';

// Export Constants
export const AUTH_USER = 'AUTH_USER',
  UNAUTH_USER = 'UNAUTH_USER',
  AUTH_ERROR = 'AUTH_ERROR',
  FORGOT_PASSWORD_REQUEST_SENT = 'AUTH_FP_REQUEST_SENT',
  RESET_PASSWORD_REQUEST = 'AUTH_RP_REQUEST',
  DEFAULT = 'AUTH_DEFAULT';

const performLogin = (dispatch, response) => {
  dispatch({ type: AUTH_USER, payload: response.user });
  storage.setItem('usrt', response.token);
  history.push(apiCaller.CLIENT_ROOT_URL);
};

export function login(reqBody, rememberMe) {
  return (dispatch) => {
    return apiCaller.auth('login', 'post', reqBody).then(
      (response) => {
        performLogin(dispatch, response);
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: UNAUTH_USER });
    storage.removeItem('usrt');
    history.replace(`${apiCaller.CLIENT_ROOT_URL}login`);
  };
}

export function activateAccount(reqBody) {
  return (dispatch) => {
    return apiCaller.auth(`activate`, 'post', reqBody).then(
      (response) => {
        performLogin(dispatch, response);
        dispatch(
          errorHandler.setSuccessMessage({
            data: {
              auth: {
                msg: 'success.auth.activated',
              },
            },
          })
        );
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}

export function forgotPassword(reqBody) {
  return (dispatch) => {
    return apiCaller.auth('forgotpassword', 'post', reqBody).then(
      (response) => {
        dispatch({ type: FORGOT_PASSWORD_REQUEST_SENT });
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}

export function resetPassword(reqBody) {
  return (dispatch) => {
    return apiCaller.auth('resetpassword', 'post', reqBody).then(
      (response) => {
        dispatch(
          errorHandler.setSuccessMessage({
            data: {
              auth: {
                msg: 'success.auth.passwordSuccesfullyReset',
              },
            },
          })
        );
        history.push(`${apiCaller.CLIENT_ROOT_URL}login`);
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}

export function resendActivationEmail(email) {
  return (dispatch) => {
    return apiCaller.auth(`resend`, 'post', { email }).then(
      (response) => {
        history.push(`${apiCaller.CLIENT_ROOT_URL}login`);
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}

export function getInfo() {
  return (dispatch) => {
    return apiCaller.auth('info', 'get', undefined, true).then((response) => {
      response.config = response.config || {};
      dispatch({
        type: AUTH_USER,
        payload: {
          ...response,
          loaded: true,
        },
      });

      return response;
    });
  };
}

export function saveAccInfo(model = {}) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('email', model.email);
    formData.append('name', model.name);
    formData.append('company', model.company);
    formData.append('photo', model.photo);

    return apiCaller.authWithFormData(`info`, formData).then(
      (response) => {
        response.config = response.config || {};
        dispatch({
          type: AUTH_USER,
          payload: {
            ...response,
            loaded: true,
          },
        });
        dispatch(
          errorHandler.setSuccessMessage({
            data: {
              auth: {
                msg: 'success.auth.infoSuccesfullySaved',
              },
            },
          })
        );

        return response;
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}

export function changePassword(model = {}) {
  return (dispatch) => {
    return apiCaller.auth(`changepassword`, 'put', model, true).then(
      (response) => {
        dispatch(
          errorHandler.setSuccessMessage({
            data: {
              auth: {
                msg: 'success.auth.passwordSuccesfullyChanged',
              },
            },
          })
        );

        return response;
      },
      (err) => {
        dispatch(errorHandler.setError(err));
      }
    );
  };
}
