import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import auth from '../../utils/auth';
import ErrorBoundary from '../ErrorBoundary';
import { getInfo } from '../../modules/Auth/AuthActions';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';

class PrivateLayout extends Component {
  componentDidMount() {
    auth.checkForToken(
      () => {
        if (!this.props.user.email) {
          this.props.getInfo();
        } else if (
          this.props.admin &&
          this.props.user &&
          (!this.props.user.partner || this.props.user.partner.role !== 'owner')
        ) {
          this.props.history.replace('/');
        }
      },
      () => {
        this.props.history.replace('/login');
      }
    );
  }

  render() {
    const Component = this.props.component;
    const route = this.props.route;

    return (
      <ErrorBoundary>
        <div>
          <Header />
          <Navigation />
          <div className="page-content">
            <Component route={route} />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInfo: () => dispatch(getInfo()),
  };
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateLayout));
