import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button } from 'react-bootstrap';

import ImageSize from '../ImageSize/ImageSize';

import styles from './Profile.scss';
let cx = classNames.bind(styles);

class Profile extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      popupVisible: false,
    };
  }

  handleClick() {
    if (!this.state.popupVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick(e) {
    // if (this.node.contains(e.target)) {
    // 	return;
    // }

    this.handleClick();
  }

  render() {
    let { className, user, email, linkToAccount, linkToUsers, onChange, onClick, profileImage } = this.props;

    return (
      <div
        className={cx('profile', className)}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div className={cx('profile_image')} onClick={this.handleClick}>
          <ImageSize src={profileImage} alt="#" />
        </div>
        <div className={cx('profile_content')} style={{ display: this.state.popupVisible ? 'block' : 'none' }}>
          <div className={cx('avatar-info')}>
            <div className={cx('avatar')}>
              <ImageSize src={profileImage} alt="" />
            </div>
            <h2>{user}</h2>
            <p>{email}</p>
          </div>
          <div className={cx('profile-action')}>
            <Link to={linkToAccount} className={cx('btn', 'btn-dark-blue', 'btn-sm')}>
              Account
            </Link>
            <Link to={linkToUsers} className={cx('btn', 'btn-blue-border', 'btn-sm')}>
              Users
            </Link>
          </div>

          <div className={cx('profile-footer')}>
            <Button variant="white" size="sm" onClick={onClick} onChange={onChange}>
              Log out
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.string,
  email: PropTypes.string,
  linkToAccount: PropTypes.string.isRequired,
  linkToUsers: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  profileImage: PropTypes.string.isRequired,
};

export default Profile;
