import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <Form.Group>
        <Form.Control type={this.state.show ? 'text' : 'password'} {...this.props} />
        <Form.Label htmlFor={this.props.id}>
          <span>{this.props.placeholder}</span>
        </Form.Label>
        <Button
          tabIndex="-1"
          variant="pass"
          className={this.state.show && 'show-password'}
          onClick={() => this.setState({ show: !this.state.show })}
        ></Button>
      </Form.Group>
    );
  }
}

PasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default PasswordInput;
