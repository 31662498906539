import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import NotificationsTable from './components/NotificationsTable';

class Notifications extends Component {
  render() {
    return [
      <Helmet key="0" title="Notifications" />,
      <Container key="1">
        <NotificationsTable />
      </Container>,
    ];
  }
}

export default Notifications;
