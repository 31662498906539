import { NOTIFICATIONS_LOADING, NOTIFICATIONS_LIST } from './NotificationsActions';

const INITIAL_STATE = {
  isLoading: true,
  results: undefined,
  page: 0,
  total: 0,
};

const NotificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_LOADING: {
      return { ...state, isLoading: action.payload || true };
    }
    case NOTIFICATIONS_LIST:
      return {
        ...state,
        isLoading: false,
        results: action.payload.results,
        page: action.payload.page,
        total: action.payload.total,
      };
    default:
      return { ...state };
  }
};

export default NotificationsReducer;
