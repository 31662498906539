import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './CheckBox.scss';
let cx = classNames.bind(styles);

class CheckBox extends Component {
  static defaultProps = {
    onChange: function () {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleColorChange = (color) => {
    if (typeof this.props.onColorChange === 'function') {
      this.props.onColorChange({
        optId: this.state.value,
        color,
      });
    }
  };

  handleChange = (e) => {
    const { checked, name } = e.target;
    this.props.onChange({ name, checked, value: this.state.value });
  };

  render() {
    const { name, className, checked, label, id, disabled, colorsChangable, ItemRenderer, rendererValues } = this.props;
    return (
      <label className={cx('check_box', className, disabled && 'disabled')}>
        <div className={cx('checkbox_label')}>
          {ItemRenderer ? (
            <ItemRenderer
              colorsChangable={colorsChangable}
              label={label}
              option={rendererValues}
              onColorChange={this.handleColorChange}
            />
          ) : (
            label
          )}
        </div>
        <input name={name} id={id} checked={checked} type="checkbox" onChange={this.handleChange} disabled={disabled} />
        <span className={cx('checkmark')}>
          <svg width="10px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
      </label>
    );
  }
}

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  ItemRenderer: PropTypes.func,
  rendererValues: PropTypes.object,
  colorsChangable: PropTypes.bool,
  onColorChange: PropTypes.func,
};

export default CheckBox;
