import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';

import appIcon from '../../assets/images/app-icon.svg';
import trello from '../../assets/images/trello.svg';
import asana from '../../assets/images/asana.svg';
import jira from '../../assets/images/jira.png';
import azure from '../../assets/images/devops.svg';
import file from '../../assets/images/nave.svg';

import styles from './Apps.scss';
const cx = classNames.bind(styles);

const icons = {
  jira,
  trello,
  asana,
  azure,
  file,
};

class Apps extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      popupVisible: false,
    };
  }

  handleClick() {
    if (!this.state.popupVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  renderApp = (appname) => {
    const urlParts = window.location.host.split('.');
    let link = `https://${appname}.${urlParts[1]}.${urlParts[2]}`;

    if (appname !== 'file') {
      link = `${link}/login?uid=${this.props.user.id}`;
    }
    if (appname === 'file') {
      link = `${link}/register?uid=${this.props.user.id}`;
    }

    return (
      <li>
        <a href={link} rel="noopener noreferrer" target="_blank">
          <img className={cx(appname)} src={icons[appname]} alt="#" /> Nave for {_.upperFirst(appname)}
        </a>
      </li>
    );
  };

  render() {
    const { className } = this.props;

    return (
      <div
        className={cx('apps', className)}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div className={cx('apps_logo')} onClick={this.handleClick}>
          <img src={appIcon} alt="#" />
        </div>
        <ul className={cx('apps_content')} style={{ display: this.state.popupVisible ? 'block' : 'none' }}>
          {this.renderApp('jira')}
          {this.renderApp('trello')}
          {this.renderApp('asana')}
          {this.renderApp('azure')}
          {this.renderApp('file')}
        </ul>
      </div>
    );
  }
}

Apps.propTypes = {
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Apps);
