/**
 * Main store function
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export function configureStore(initialState = {}) {
  const middlewares = [];

  // if (process.env.NODE_ENV === 'development') {
  if (true) {
    const { logger } = require('redux-logger');

    middlewares.push(logger);
  }

  middlewares.push(thunk);

  // Middleware and store enhancers
  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(rootReducer, initialState, compose(...enhancers));
  return store;
}
