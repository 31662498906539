import React, { Component } from 'react';
import { withRouter } from 'react-router';

class NotFound extends Component {
  render() {
    return (
      <div>
        <h1>Not found</h1>
        <button onClick={() => this.props.history.goBack()}>Go back</button>
      </div>
    );
  }
}

export default withRouter(NotFound);
