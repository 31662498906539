import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { forgotPassword } from '../AuthActions';

import logo from '../img/nave.svg';
import loginImg from '../img/logo-image.svg';

// Import Style
import styles from '../Auth.scss';
const cx = classNames.bind(styles);

class ForgotPass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.forgotPassword({
      email: this.state.email,
    });
  };

  render() {
    return (
      <div className={cx('login_bg')}>
        <div className={cx('login_form')}>
          {!this.props.rpSent ? (
            <div className={cx('left_part')}>
              <Form className={cx('form')} noValidate onSubmit={this.handleSubmit}>
                <h1>Forgot your password?</h1>
                <p className="mb-4">
                  Enter your email here and we'll send a link to reset it. If you are still having trouble, <br />
                  <a href="mailto:hello@getnave.com">get in touch</a> with us and we'll take care of it.
                </p>
                <Form.Group>
                  <Form.Control
                    autoFocus
                    placeholder="Email"
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <Form.Label htmlFor="email">
                    <span>Email</span>
                  </Form.Label>
                </Form.Group>

                <Button type="submit" className="mt-4" variant="blue">
                  Submit
                </Button>
                <Link className="link-back mt-4" to="/login">
                  Back
                </Link>
              </Form>
            </div>
          ) : (
            <div className={cx('left_part')}>
              <Form className={cx('form')}>
                <h1>Partner Portal</h1>
                <p className="mb-4">Thank you! We sent you an email with instructions on how to reset your password</p>

                <Link className="link-back mt-4" to="/login">
                  Back to Login
                </Link>
              </Form>
            </div>
          )}

          <div className={cx('right_part')}>
            <div className={cx('logo')}>
              <img src={logo} alt="" />
            </div>
            <div className={cx('logo_icon')}>
              <img src={loginImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (body) => dispatch(forgotPassword(body)),
  };
};

function mapStateToProps(state) {
  return {
    rpSent: state.auth.rpSent,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPass));
