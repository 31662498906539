// Import Actions
import { SET_ERROR, SET_INFO, SET_SUCCESS, CLEAN } from './actions';

// Initial State
const initialState = {
  error: '',
  success: '',
};

const ErrorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: action.error,
      };
    case SET_SUCCESS:
      return {
        success: action.msg,
      };
    case SET_INFO:
      return {
        info: action.msg,
      };
    case CLEAN: {
      return initialState;
    }

    default:
      return state;
  }
};

// Export Reducer
export default ErrorsReducer;
