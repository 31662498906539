import React from 'react';
import PropTypes from 'prop-types';

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;
const DEFAULT_LABEL_PLACEHOLDER = 'Click To Edit';

export default class EditableLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: this.props.isEditing || false,
      text: this.props.text || '',
      origText: this.props.text || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setState({
        text: this.props.text || '',
        origText: this.props.text || '',
      });
    }

    if (prevProps.isEditing !== this.props.isEditing) {
      this.setState({
        isEditing: this.state.isEditing || this.props.isEditing || false,
      });
    }
  }

  isTextValueValid = () => {
    if (this.props.inputType === 'number') {
      return typeof this.state.text != 'undefined' && this.state.text > 0;
    }

    return typeof this.state.text != 'undefined' && this.state.text.trim().length > 0;
  };

  handleFocus = () => {
    if (this.state.isEditing) {
      // eslint-disable-next-line
      if (typeof this.props.onFocusOut === 'function' && this.state.origText != this.state.text) {
        if (!this.props.allowEmpty && !this.state.text) {
          return false;
        }

        this.props.onFocusOut(this.state.text);
      }
    } else {
      if (typeof this.props.onFocus === 'function') {
        this.props.onFocus(this.state.text);
      }
    }

    if (this.isTextValueValid()) {
      this.setState({
        isEditing: !this.state.isEditing,
      });
    } else {
      if (this.state.isEditing) {
        this.setState({
          isEditing: this.props.emptyEdit || false,
        });
      } else {
        this.setState({
          isEditing: true,
        });
      }
    }
  };

  handleChange = () => {
    this.setState({
      text: this.textInput.value,
    });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      this.handleEnterKey();
    } else if (e.keyCode === ESC_KEY_CODE) {
      this.handleEscKey();
    }
  };

  handleEnterKey = () => {
    this.handleFocus();
  };

  handleEscKey = () => {
    this.setState({ text: this.state.origText }, this.handleFocus);
  };

  render() {
    if (this.state.isEditing) {
      return (
        <input
          className={this.props.inputClassName}
          ref={(input) => {
            this.textInput = input;
          }}
          value={this.state.text}
          onChange={this.handleChange}
          onBlur={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          type={this.props.inputType}
          min={this.props.min}
          style={{
            width: this.props.inputWidth,
            height: this.props.inputHeight,
            fontSize: this.props.inputFontSize,
            fontWeight: this.props.inputFontWeight,
            borderWidth: this.props.inputBorderWidth,
          }}
          maxLength={this.props.inputMaxLength}
          placeholder={this.props.inputPlaceHolder}
          tabIndex={this.props.inputTabIndex}
          autoFocus
        />
      );
    }

    const labelText = this.isTextValueValid()
      ? this.state.text
      : this.props.labelPlaceHolder || DEFAULT_LABEL_PLACEHOLDER;
    return (
      <span
        className={this.props.labelClassName}
        onClick={this.handleFocus}
        style={{
          fontSize: this.props.labelFontSize,
          fontWeight: this.props.labelFontWeight,
        }}
      >
        {labelText}
      </span>
    );
  }
}

EditableLabel.defaultProps = {
  inputType: 'text',
  allowEmpty: false,
};

EditableLabel.propTypes = {
  isEditing: PropTypes.bool,
  emptyEdit: PropTypes.bool,

  labelClassName: PropTypes.string,
  labelFontSize: PropTypes.string,
  labelFontWeight: PropTypes.string,
  labelPlaceHolder: PropTypes.string,

  inputType: PropTypes.string,
  inputMaxLength: PropTypes.number,
  inputPlaceHolder: PropTypes.string,
  inputTabIndex: PropTypes.number,
  inputWidth: PropTypes.string,
  inputHeight: PropTypes.string,
  inputFontSize: PropTypes.string,
  inputFontWeight: PropTypes.string,
  inputClassName: PropTypes.string,
  inputBorderWidth: PropTypes.string,

  onFocus: PropTypes.func,
  onFocusOut: PropTypes.func,
};
