import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import logger from '../utils/logger';

class ErrorBoundary extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    logger.setup();
  }

  componentDidCatch(error, info) {
    logger.report(error, info);
    this.props.history.replace('/500');
  }

  startErrorLog() {
    window.onerror = (message, file, line, column, errorObject) => {
      column = column || (window.event && window.event.errorCharacter);
      let stack = errorObject ? errorObject.stack : null;

      if (!stack) {
        stack = []; // eslint-disable-next-line
        let f = arguments.callee.caller;
        while (f) {
          stack.push(f.name);
          f = f.caller;
        }
        errorObject['stack'] = stack;
      }

      logger.report({
        message: message,
        stack: stack,
      });
      // window && window.location && window.location.replace('/500');

      return true;
    };
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
