import _ from 'lodash';

import apiCaller from '../../utils/apiCaller';
import urlHelper from '../../utils/url';
import errorHandler from '../Common/ErrorHandler';

// Export Constants
export const DASHBOARDS_LOADING = 'M_DASHBOARDS_LOADING',
  DASHBOARDS_LIST = 'M_DASHBOARDS_LIST';

export function getDashboards(opt = {}) {
  return (dispatch) => {
    dispatch({ type: DASHBOARDS_LOADING });

    let url = 'jobs';
    url += urlHelper.buildQuery(opt);

    apiCaller
      .api(url, 'get')
      .then((res) => {
        dispatch({
          type: DASHBOARDS_LIST,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function resyncDashboard(id) {
  return (dispatch) => {
    apiCaller
      .api(`jobs/${id}`, 'put')
      .then(() => {
        dispatch(getDashboards());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function resyncAllDashboards() {
  return (dispatch, getState) => {
    const dashboards = getState().maintenance.results;
    const ids = _(dashboards)
      .map((d) => d.dashboardId)
      .join();

    apiCaller
      .api(`jobs/all/${ids}`, 'put')
      .then(() => {
        dispatch(getDashboards());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}
