import { STATUS_LOADING, STATUS_LIST, STATUS_UPDATE } from './StatusActions';

const INITIAL_STATE = {
  isLoading: true,
  results: undefined,
  page: 0,
  total: 0,
};

const StatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STATUS_LOADING: {
      return { ...state, isLoading: action.payload || true };
    }
    case STATUS_LIST:
      return {
        ...state,
        isLoading: false,
        results: action.payload.results,
        stats: action.payload.statistic,
        page: action.payload.page,
        total: action.payload.total,
      };
    case STATUS_UPDATE: 
      return {
        ...state,
        results: state.results.map(r => {
          return r.dashboardId === action.payload.dashboardId ? action.payload : r;    
        })
      }
    default:
      return { ...state };
  }
};

export default StatusReducer;
