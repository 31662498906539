import fetch from 'isomorphic-fetch';
import storage from './storage';

export const CLIENT_ROOT_URL =
  typeof window === 'undefined' || process.env.NODE_ENV === 'test'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8082}/`
    : '/';

export const API_URL =
  typeof window === 'undefined' || process.env.NODE_ENV === 'test'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8082}/api`
    : '/api';

export const AUTH_URL =
  typeof window === 'undefined' || process.env.NODE_ENV === 'test'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8082}/auth`
    : '/auth';

function getToken() {
  return storage.getItem('usrt') || null;
}

function request(url, method, body, authenticated, opt = {}) {
  const headers = opt.formData ? {} : { 'content-type': 'application/json' };
  if (authenticated) {
    const token = getToken();
    if (token) {
      headers['Authorization'] = token;
    } else {
      global.window && window.location && window.location.replace('/login');
      return Promise.reject();
    }
  }

  if (!opt.formData && body) {
    body = JSON.stringify(body);
  }

  return fetch(url, {
    headers: headers,
    method,
    body,
  })
    .then((response) => {
      if (response.status === 401) {
        storage.removeItem('usrt');
        global.window && window.location && window.location.replace('/login');
        return Promise.reject();
      }

      if (response.status === 502) {
        global.window && window.location && window.location.replace('/downtime');
        return Promise.reject();
      }

      return response
        .json()
        .then((json) => ({ json, response }))
        .catch((err) => {
          return {
            json: { message: 'Empty response' },
            response: { ...response, empty: true, ok: false },
          };
        });
    })
    .then(({ json, response }) => {
      if (response.empty) {
        return Promise.reject();
      }
      if (!response.ok) {
        return Promise.reject(json);
      }

      return json;
    });
}

export function api(endpoint, method = 'get', body = undefined, authenticated = true) {
  return request(`${API_URL}/${endpoint}`, method, body, authenticated);
}

export function apiWithFormData(endpoint, body) {
  return request(`${API_URL}/${endpoint}`, 'post', body, true, {
    formData: true,
  });
}

export function auth(endpoint, method = 'get', body, authenticated) {
  return request(`${AUTH_URL}/${endpoint}`, method, body, authenticated);
}

export function authWithFormData(endpoint, body) {
  return request(`${AUTH_URL}/${endpoint}`, 'put', body, true, {
    formData: true,
  });
}

export default {
  CLIENT_ROOT_URL,
  API_URL,
  AUTH_URL,
  api,
  auth,
  apiWithFormData,
  authWithFormData,
};
