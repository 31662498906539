import apiCaller from '../../utils/apiCaller';
import urlHelper from '../../utils/url';
import errorHandler from '../Common/ErrorHandler';

export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING',
NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST';

export function handleError(error) {
  return errorHandler.setError(error);
}

export function getNotifications(opt = {}) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATIONS_LOADING });

    let url = 'notifications';
    url += urlHelper.buildQuery(opt);

    apiCaller
      .api(url, 'get')
      .then((res) => {
        dispatch({
          type: NOTIFICATIONS_LIST,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function createNotification(model) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATIONS_LOADING });
    apiCaller
      .api('notifications', 'put', model)
      .then(() => {
        dispatch(getNotifications());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function updateNotification(model) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATIONS_LOADING });
    apiCaller
      .api(`notifications/${model._id}`, 'put', model)
      .then(() => {
        dispatch(getNotifications());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function removeNotification(id) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATIONS_LOADING });
    apiCaller
      .api(`notifications/${id}`, 'delete')
      .then(() => {
        dispatch(getNotifications());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}
