import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Button, Modal, Pagination, Form, Row, Col } from 'react-bootstrap';

import Loading from '../../../components/Loading/Loading';
import throttle from '../../../utils/throttle';
import pagination from '../../../utils/pagination';
import { getAccounts, tranferSubscription } from '../AccountsActions';

import styles from '../../../assets/styles/_table.scss';
import modalStyles from './transfer-modal.scss';
let cx = classNames.bind(styles, modalStyles);

class TransferSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: props.visible,
      search: '',
      isLoading: true,
      results: [],
      page: 0,
      total: 0,
      selectedAccount: '',
    };

    this.getAccountsThrottled = throttle(this.getAccounts, 500, this);
  }

  getAccounts = () => {
    const { platform, filter, sort, search, page } = this.state;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props
          .getAccounts({
            platform,
            filter,
            sort,
            search,
            page,
            pageSize: this.props.pageSize,
          })
          .then((res) => {
            this.setState({
              isLoading: false,
              results: _.filter(res.results, (r) => {
                return r._id !== this.props.accountId;
              }),
              page: res.page,
              total: res.total,
            });
          });
      }
    );
  };

  goto = (page = 1) => {
    if (page < 1) {
      page = 1;
    }

    this.setState({ page }, this.getAccounts);
  };

  resetState = () => {
    this.setState({
      isOpened: false,
      isLoading: true,
      results: [],
      page: 0,
      total: 0,
      selectedAccount: '',
    });
  };

  handleChange = (ev) => {
    this.setState({ search: ev.target.value, page: 1 });
    this.getAccountsThrottled();
  };

  onButtonClick = () => {
    if (!this.state.isOpened) {
      this.setState({ isOpened: true });
      this.getAccounts();
    }
  };

  onClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.resetState();

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  onConfirm = (event) => {
    event.stopPropagation();

    this.props
      .tranferSubscription(this.state.selectedAccount)
      .then(() => {
        this.resetState();
        if (typeof this.props.onClose === 'function') {
          this.props.onClose(true);
        }
      })
      .catch(() => {});
  };

  renderPagination = () => {
    const pages = pagination(this.state.total, this.state.page, this.props.pageSize, 5);

    if (pages.totalPages < 2) {
      return <div></div>;
    }
    return (
      <Pagination>
        {pages.currentPage > 1 && <Pagination.First onClick={() => this.goto(1)} />}
        {pages.currentPage > 1 && <Pagination.Prev onClick={() => this.goto(pages.currentPage - 1)} />}
        {pages.pages.map((p, i) => {
          return (
            <Pagination.Item key={i} active={p === pages.currentPage} onClick={() => this.goto(p)}>
              {p}
            </Pagination.Item>
          );
        })}
        {pages.currentPage < pages.totalPages && <Pagination.Next onClick={() => this.goto(pages.currentPage + 1)} />}
        {pages.currentPage < pages.totalPages && <Pagination.Last onClick={() => this.goto(pages.totalPages)} />}
      </Pagination>
    );
  };

  renderRow = (data, i) => {
    return (
      <Row
        key={i}
        onClick={() => this.setState({ selectedAccount: data._id })}
        className={cx({ selected: this.state.selectedAccount === data._id }, 'selectable', 'row-item')}
      >
        {/* Col 1 */}
        <Col xs={12} md={4} className={cx('col-one')}>
          <span>{data.name}</span>
        </Col>
        {/* Col 2 */}
        <Col className={cx('col-two')}>
          <span>{data.email}</span>
        </Col>
        {/* Col 3 */}
        <Col className={cx('col-three')}>
          <span>{_.upperFirst(data.application)}</span>
        </Col>
      </Row>
    );
  };

  render() {
    const { isLoading, results, total } = this.state;

    const modal = (
      <Modal
        show={this.state.isOpened}
        onHide={this.onClose}
        className={cx('transfer_modal')}
        keyboard={this.props.keyboard}
        backdrop={this.props.backdrop}
        enforceFocus={this.props.enforceFocus}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>Transfer Subscription</h5>
            <Button variant="clear" onClick={this.onClose}></Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={cx('table-content')}>
              <div className={cx('table-filter')}>
                <div className={cx('filter-content')} id="filter-content"></div>
                <Form.Group controlId="Search" className="u-di search-form white">
                  <Form.Control
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={this.handleChange}
                    value={this.state.search}
                  />
                </Form.Group>
              </div>

              {isLoading && (
                <div className={cx('table-loading')}>
                  <Loading />
                </div>
              )}
              {!isLoading && total === 0 && (
                <div className={cx('no_data')}>
                  <p> No data available </p>
                </div>
              )}

              <div className={cx('table-list')}>
                <Row className={cx('row-item')}>
                  {/* Col 1 */}
                  <Col xs={12} md={4} className={cx('col-one')}>
                    <span>Name</span>
                  </Col>
                  {/* Col 2 */}
                  <Col className={cx('col-two')}>
                    <span>Email</span>
                  </Col>
                  {/* Col 3 */}
                  <Col className={cx('col-three')}>
                    <span>Application</span>
                  </Col>
                </Row>
                {!isLoading &&
                  results &&
                  _.map(_.take(results, this.props.pageSize), (data, i) => this.renderRow(data, i))}
              </div>
            </div>
            {total > 0 && this.renderPagination()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="blue" size="sm" onClick={this.onConfirm} disabled={!this.state.selectedAccount}>
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>
    );

    var content;
    if (this.props.children) {
      var btn = React.Children.only(this.props.children);
      content = React.cloneElement(
        btn,
        {
          onClick: this.onButtonClick,
          style: this.props.style,
        },
        btn.props.children,
        modal
      );
    } else {
      content = (
        <Button onClick={this.onButtonClick} style={this.props.style}>
          {this.props.buttonText}
          {modal}
        </Button>
      );
    }
    return content;
  }
}

TransferSubscription.propTypes = {
  accountId: PropTypes.string.isRequired,
  buttonText: PropTypes.node,
  keyboard: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  enforceFocus: PropTypes.bool,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

TransferSubscription.defaultProps = {
  pageSize: 10,
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getAccounts: (opt) => dispatch(getAccounts(opt, true)),
    tranferSubscription: (toAccId) => dispatch(tranferSubscription(props.accountId, toAccId)),
  };
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferSubscription);
