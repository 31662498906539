import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';

import StatusTable from './components/StatusTable';

class Status extends Component {
  render() {
    return [
      <Helmet key="0" title="Status" />,
      <Container key="1">
        <StatusTable pagination={true} />
      </Container>,
    ];
  }
}

export default Status;
