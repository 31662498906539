import apiCaller from '../../utils/apiCaller';
import errorHandler from '../Common/ErrorHandler';

// Export Constants
export const IS_LOADING = 'CHAT_FILTER_IS_LOADING',
  CHAT_FILTER = 'CHAT_FILTER';

export function handleError(error) {
  return errorHandler.setError(error);
}

export function getChatFilter() {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });

    let url = 'chat-filter';

    apiCaller
      .api(url, 'get')
      .then((res) => {
        dispatch({
          type: CHAT_FILTER,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
        dispatch({ type: IS_LOADING, payload: true });
      });
  };
}

export function createChatFilter(model) {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });
    apiCaller
      .api('chat-filter', 'put', model)
      .then(() => {
        dispatch(getChatFilter());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
        dispatch({ type: IS_LOADING, payload: true });
      });
  };
}

export function updateChatFilter(model) {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });
    apiCaller
      .api(`chat-filter/${model._id}`, 'put', model)
      .then(() => {
        dispatch(getChatFilter());
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
        dispatch({ type: IS_LOADING, payload: true });
      });
  };
}
