import React, { Component } from 'react';
import { Provider } from 'react-redux';

import { configureStore } from './store';
import App from './App';

// Base stylesheet
require('react-toastify/dist/ReactToastify.min.css');
require('nave-components/dist/assets/styles/styles.css');
require('./main.scss');
require('./assets/styles/styles.scss');

class AppProvider extends Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <App />
      </Provider>
    );
  }
}

export default AppProvider;
