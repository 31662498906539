import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _ from 'lodash';
import moment from 'moment';
import { Button, Row, Col, Dropdown } from 'react-bootstrap';
import { Confirm } from 'nave-components/dist/components/Modal/Confirm';

import Loading from '../../components/Loading/Loading';
import EllipsisWithTooltip from '../../components/EllipsisWithTooltip';
import { getUsers, transferOwnership } from './AccountsActions';

import settingIcon from '../../assets/images/settings-gray-icon.svg';
import transferOwnershipIcon from '../../assets/images/transfer-ownership.svg';

import styles from '../../assets/styles/_table.scss';
let cx = classNames.bind(styles);

class Users extends Component {
  componentDidMount() {
    this.props.getUsers(this.props.match.params.id);
  }

  renderRow = (data, i) => {
    return (
      <Row key={i} className={cx('row-item')}>
        {/* Col 1 */}
        <Col xs={12} md={3} className={cx('col-one')}>
          <p>
            <label>Name:</label>
            <span>{data.name || data.id}</span>
          </p>
          <p>
            <label>Email:</label>
            <span>{data.email}</span>
          </p>
          <p>
            <label>Role:</label>
            <span>{_.upperFirst(data.role)}</span>
          </p>
          {data.webhook && (
            <p>
              <p>
                <label>Webhook JQL:</label>
                <span>{data.webhook.jql}</span>
              </p>
              <p>
                <label>Last webhook update received at:</label>
                <span>{data.webhook.lastCalledAt ? moment(data.webhook.lastCalledAt).format('DD MMM yyyy HH:mm z') : ''}</span>
              </p>
            </p>
          )}
        </Col>

        {/* Col 2 */}
        <Col className={cx('col-two')}>
          <p>
            <label>User Id:</label>
            <span>{_.upperFirst(data.id)}</span>
          </p>
          <p>
            <label>Display Token:</label>
            <span>{data.displayToken}</span>
          </p>
          {data.webhook && (
            <p>
              <p>
                <label>Webhook URL:</label>
                <EllipsisWithTooltip url={data.webhook.url} />                
              </p>
              <p>
                <label>Webhook prolonged at:</label>
                <span>
                  {data.webhook.lastProlongedDate ? moment(data.webhook.lastProlongedDate).format('DD MMM yyyy HH:mm z') : ''}
                </span>
              </p>
            </p>
          )}
        </Col>

        {/* Col 3 */}

        <Col className={cx('col-three')}>
          <p>
            <label>Last active on:</label>
            <span>{moment(data.lastActiveOn).format('DD MMM yyyy HH:mm z')}</span>
          </p>
          {data.webhook && (
            <p>
              <p>
                <label>External Webhook Id:</label>
                <span>{data.webhook.externalId}</span>
              </p>
              <p>
                <label>Webhook created at:</label>
                <span>{moment(data.webhook.createdAt).format('DD MMM yyyy HH:mm z')}</span>
              </p>
            </p>
          )}
        </Col>

        {/* Col 5 */}
        <Col className={cx('col-five')}>
          <Dropdown className="dropdown-settings-menu">
            <Dropdown.Toggle className="btn-menu">
              <img src={settingIcon} alt="#" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href={`https://${data.application}.getnave.com/api/account/login/${data.id}`}
                target="_blank"
              >
                Remote login
              </Dropdown.Item>
              <Dropdown.Item>
                <Confirm
                  body={
                    <p>
                      Are you sure you want to transfer <br />
                      account ownership to {data.name}?
                    </p>
                  }
                  confirmText="Transfer"
                  icon={transferOwnershipIcon}
                  title="Transfer ownership"
                  onConfirm={() => this.props.transferOwnership(this.props.match.params.id, data.id)}
                >
                  <Button>Transfer ownership</Button>
                </Confirm>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    );
  };

  render() {
    const { isLoading, users } = this.props;
    return [
      <Helmet key="0" title="Users" />,
      <Container key="1">
        <div className={cx('table-content')}>
          {isLoading && (
            <div className={cx('table-loading')}>
              <Loading />
            </div>
          )}
          {!isLoading && users.length === 0 && (
            <div className={cx('no_data')}>
              <p> No data available </p>
            </div>
          )}

          <div className={cx('table-list no-padding')}>
            {!isLoading && users && _.map(users, (data, i) => this.renderRow(data, i))}
          </div>
        </div>
      </Container>,
    ];
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (id, opt) => dispatch(getUsers(id, opt)),
    transferOwnership: (accId, userId) => dispatch(transferOwnership(accId, userId)),
  };
};

function mapStateToProps(state) {
  return {
    users: state.accounts.users,
    isLoading: state.accounts.isLoading,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
