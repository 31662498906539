import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';

import PartnersTable from './components/PartnersTable';

class Partners extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user && this.props.user.partner && this.props.user.partner.role === 'partner') {
      this.props.history.replace('/');
    }
  }

  render() {
    return [
      <Helmet title="Partners" />,
      <Container>
        <PartnersTable pagination={true} />
      </Container>,
    ];
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(Partners));
