import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Button, Form } from 'react-bootstrap';

import PasswordInput from '../../../components/PasswordInput';
import errorHandler from '../../Common/ErrorHandler';
import { login } from '../AuthActions';

import logo from '../img/nave.svg';
import loginImg from '../img/logo-image.svg';

// Import Style
import styles from '../Auth.scss';
const cx = classNames.bind(styles);

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
    };
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.email) {
      this.props.setError({
        data: {
          email: {
            msg: 'error.email.empty',
          },
        },
      });
      return false;
    }

    if (!this.state.password) {
      this.props.setError({
        data: {
          password: {
            msg: 'error.password.empty',
          },
        },
      });
      return false;
    }

    this.props.login(
      {
        email: this.state.email,
        password: this.state.password,
      },
      this.state.rememberMe
    );
  };

  render() {
    return (
      <div className={cx('login_bg')}>
        <div className={cx('login_form')}>
          <div className={cx('left_part')}>
            <Form className={cx('form')} noValidate onSubmit={this.handleSubmit}>
              <h1>Partner Portal</h1>
              <Form.Group>
                <Form.Control
                  autoFocus
                  placeholder="Email"
                  name="email"
                  type="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Label htmlFor="email">
                  <span>Email</span>
                </Form.Label>
              </Form.Group>
              <PasswordInput
                name="password"
                id="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <div className={cx('forgot-password')}>
                <Link to="/forgotpassword">Forgot password?</Link>
              </div>

              <Button variant="blue" type="submit">
                Login
              </Button>
            </Form>
          </div>

          <div className={cx('right_part')}>
            <div className={cx('logo')}>
              <img src={logo} alt="" />
            </div>
            <div className={cx('logo_icon')}>
              <img src={loginImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setError: (err) => dispatch(errorHandler.setError(err, { log: false })),
    login: (body) => dispatch(login(body)),
  };
};

export default connect(undefined, mapDispatchToProps)(Login);
