import { IS_LOADING, STATS } from './DashboardActions';

const INITIAL_STATE = {
  isLoading: true,
  stats: {
    jira: undefined,
    trello: undefined,
    asana: undefined,
    azure: undefined,
    file: undefined,
    course: { all: 0, enrolled: 0, contacts: [] },
  },
};

const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING: {
      return { ...state, isLoading: action.payload || true };
    }
    case STATS:
      return { ...state, isLoading: false, stats: action.payload };
    default:
      return { ...state };
  }
};

export default DashboardReducer;
