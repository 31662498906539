import apiCaller from '../../utils/apiCaller';
import errorHandler from '../Common/ErrorHandler';

// Export Constants
export const IS_LOADING = 'DASHBOARD_IS_LOADING',
  STATS = 'DASHBOARD_STATS';

export function getStats() {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });

    apiCaller
      .api('stats', 'get')
      .then((res) => {
        dispatch({
          type: STATS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}
