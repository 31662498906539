import storage from './storage';

function getTokenFromHash(tokenName) {
  let token = window.location.hash.substring(1) || '';

  if (token.indexOf('%7B%22') === 0) {
    return '';
  }

  const hashIndex = token.indexOf('#');
  if (hashIndex > -1) {
    token = token.substring(0, hashIndex);
  }

  return token;
}

function setup(tokenName, cb) {
  const token = getTokenFromHash(tokenName);

  if (token) {
    storage.setItem(tokenName, 'Bearer ' + token);
    window.location.replace('/');

    typeof cb === 'function' && cb();
  }
}

export function checkForToken(cb, errCb) {
  const token = storage.getItem('usrt');

  if (token) {
    typeof cb === 'function' && cb();
  } else {
    typeof errCb === 'function' && errCb();
  }
}

export function getAuth(cb) {
  setup('usrt', cb);
}

export default {
  checkForToken,
  getAuth,
};
