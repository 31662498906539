import { combineReducers } from 'redux';

import auth from './modules/Auth/AuthReducer';
import { errors } from './modules/Common/ErrorHandler';
import dashboard from './modules/Dashboard/DashboardReducer';
import accounts from './modules/Accounts/AccountsReducer';
import chat from './modules/Accounts/ChatFilterReducer';
import partners from './modules/Partners/PartnersReducer';
import users from './modules/Users/UsersReducer';
import maintenance from './modules/Maintenance/MaintenanceReducer';
import notifications from './modules/Notifications/NotificationsReducer';
import status from './modules/Status/StatusReducer';

// Combine all reducers into one root reducer
export default combineReducers({
  auth,
  errors,
  dashboard,
  accounts,
  chat,
  partners,
  users,
  maintenance,
  notifications,
  status,
});
