import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames/bind';

import Loading from '../../components/Loading/Loading';
import { getStats } from './DashboardActions';
import AccountsTable from '../Accounts/components/AccountsTable';

import styles from './Dashboard.scss';
let cx = classNames.bind(styles);

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          backgroundColor: null,
          marginRight: 0,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: '',
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return `${this.key}: ${this.y}`;
          },
        },
        plotOptions: {
          pie: {
            innerSize: '50%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.props.getStats();
  }

  renderDigitalCourseStats = (stats = {}) => {
    if (this.props.statsLoading || (stats.potential === 0 && stats.enrolled === 0)) {
      return (
        <Card className={cx('card_chart')}>
          <Card.Header as="h5">Digital Course</Card.Header>
          <Card.Body>
            {this.props.statsLoading ? (
              <Loading />
            ) : (
              <div className={cx('no_data')}>
                <p> No data available </p>
              </div>
            )}
          </Card.Body>
        </Card>
      );
    }

    const options = {
      ...this.state.chartOptions,
      series: [
        {
          data: [
            {
              name: 'Potential students',
              color: '#59caba',
              y: stats.potential,
            },
            {
              name: 'Enrolled students',
              color: '#59a2ca',
              y: stats.enrolled,
            },
          ],
        },
      ],
    };

    return (
      <Card className={cx('card_chart')}>
        <Card.Header as="h5">Digital Course</Card.Header>
        <Card.Body>
          <Row className={cx('chart_label')}>
            <Col xs={12} md={12} lg={6}>
              <strong>Potential students:</strong> {stats.potential}
            </Col>
          </Row>
          <Row className={cx('chart_label')}>
            <Col xs={12} md={12} lg={6}>
              <strong>Enrolled students:</strong> {stats.enrolled}
            </Col>
          </Row>
          <div className={cx('chart')}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderAppStats = (app, stats) => {
    if (
      this.props.statsLoading ||
      (stats.trials === 0 &&
        stats.expiredTrials === 0 &&
        stats.cancelled === 0 &&
        stats.active.monthly + stats.active.annual === 0)
    ) {
      return (
        <Card className={cx('card_chart')}>
          <Card.Header as="h5">{_.upperFirst(app)}</Card.Header>
          <Card.Body>
            {this.props.statsLoading ? (
              <Loading />
            ) : (
              <div className={cx('no_data')}>
                <p> No data available </p>
              </div>
            )}
          </Card.Body>
        </Card>
      );
    }

    const options = {
      ...this.state.chartOptions,
      series: [
        {
          data: [
            {
              name: 'Trials',
              color: '#59caba',
              y: stats.trials,
            },
            {
              name: 'Expired trials',
              color: '#59a2ca',
              y: stats.expiredTrials,
            },
            {
              name: 'Active',
              color: '#d172a9',
              y: stats.active.monthly + stats.active.annual,
            },
            {
              name: 'Canceled',
              color: '#f7d16d',
              y: stats.cancelled,
            },
          ],
        },
      ],
    };

    return (
      <Card className={cx('card_chart')}>
        <Card.Header as="h5">{_.upperFirst(app)}</Card.Header>
        <Card.Body>
          <Row className={cx('chart_label')}>
            <Col xs={12} md={12} lg={6}>
              <strong>
                Active:
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={cx('tooltip_blue')}>
                      <p>
                        <label>Monthly:</label> {stats.active.monthly}
                      </p>
                      <p>
                        <label>Annual:</label> {stats.active.annual}
                      </p>
                    </Tooltip>
                  }
                >
                  <span className="tooltip_info">i</span>
                </OverlayTrigger>
              </strong>{' '}
              {stats.active.monthly + stats.active.annual}
            </Col>
            <Col xs={12} md={12} lg={6}>
              <strong>Canceled:</strong> {stats.cancelled}
            </Col>
          </Row>
          <Row className={cx('chart_label')}>
            <Col xs={12} md={12} lg={6}>
              <strong>Trials:</strong> {stats.trials}
            </Col>
            <Col xs={12} md={12} lg={6}>
              <strong>Expired trials:</strong> {stats.expiredTrials}
            </Col>
          </Row>
          <div className={cx('chart')}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderStats = () => {
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            {this.renderAppStats('jira', this.props.stats.jira)}
          </Col>
          <Col xs={12} md={4}>
            {this.renderAppStats('trello', this.props.stats.trello)}
          </Col>
          <Col xs={12} md={4}>
            {this.renderAppStats('asana', this.props.stats.asana)}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            {this.renderAppStats('azure', this.props.stats.azure)}
          </Col>
          <Col xs={12} md={4}>
            {this.renderAppStats('file', this.props.stats.file)}
          </Col>
          <Col xs={12} md={4}>
            {this.renderDigitalCourseStats(this.props.stats.course)}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <Container className="dashboard">
        <Helmet title="Dashboard" />
        {this.renderStats()}

        <AccountsTable pagination={false} show={5} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStats: () => dispatch(getStats()),
  };
};

function mapStateToProps(state) {
  return {
    stats: state.dashboard.stats,
    statsLoading: state.dashboard.isLoading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
