import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { saveAccInfo, changePassword } from '../Auth/AuthActions';
import PasswordInput from '../../components/PasswordInput';
import ImageSize from '../../components/ImageSize/ImageSize';
import profileImage from '../../assets/images/default-image.svg';

import styles from './Account.scss';
let cx = classNames.bind(styles);

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.user.email) {
      this.updateUserState();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.updateUserState();
    }
  }

  updateUserState() {
    this.setState({
      name: this.props.user.name,
      email: this.props.user.email,
      company: this.props.user.partner.name,
      photoUrl: this.props.user.photo,
    });
  }

  triggerInputFile = () => {
    this.fileInput.click();
  };

  handleFileUpload = (ev) => {
    this.setState({
      photoUrl: ev.target.files.length ? URL.createObjectURL(ev.target.files[0]) : undefined,
      photo: ev.target.files.length ? ev.target.files[0] : undefined,
    });
  };

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  saveAccInfo = () => {
    this.props
      .saveAccInfo({
        name: this.state.name,
        email: this.state.email,
        company: this.state.company,
        photo: this.state.photo,
      })
      .then(() => {});
  };

  sendResetPassword = () => {
    this.props
      .changePassword({
        newPassword: this.state.newPassword,
        oldPassword: this.state.oldPassword,
      })
      .then(() => {
        this.setState({
          oldPassword: '',
          newPassword: '',
        });
      });
  };

  render() {
    const { user } = this.props;
    return (
      <div>
        <Helmet title="Account" />
        <Container>
          <Card className={cx('card_account')}>
            <Card.Header>
              <div className={cx('avatar')} onClick={this.triggerInputFile}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  onChange={this.handleFileUpload}
                />
                <div className={cx('images')}>
                  <ImageSize alt="profile" src={this.state.photoUrl || profileImage} />
                </div>
                <span className={cx('label_upload_photo')}>Upload photo</span>
              </div>
              <h5>Edit account</h5>
            </Card.Header>
            <Card.Body>
              <p>Profile information</p>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name="name"
                      type="text"
                      id="name"
                      placeholder="Name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    <Form.Label htmlFor="name">
                      <span>Name</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <Form.Group>
                    <Form.Control
                      name="email"
                      type="email"
                      id="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <Form.Label htmlFor="email">
                      <span>Email</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Form.Group>
                    <Form.Control
                      name="company"
                      type="text"
                      id="company"
                      placeholder="Company Name"
                      value={this.state.company}
                      onChange={this.handleChange}
                    />
                    <Form.Label htmlFor="company">
                      <span>Company Name</span>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              {user.partner && (
                <Row>
                  <Col>
                    <Form.Group className={cx('form_url')}>
                      <Form.Control
                        disabled
                        name="loginUrl"
                        type="text"
                        id="loginUrl"
                        placeholder="Login URL"
                        value={`https://getnave.com/kanban-dashboards${
                          user.partner.refId ? `?referral=${user.partner.refId}` : ''
                        }`}
                      />
                      <Form.Label htmlFor="loginUrl">
                        <span>Referral link</span>
                      </Form.Label>
                      <CopyToClipboard
                        text={`https://getnave.com/kanban-dashboards${
                          user.partner.refId ? `?referral=${user.partner.refId}` : ''
                        }`}
                        onCopy={() => this.setState({ copied: true })}
                      >
                        <span className="link">{this.state.copied ? 'Copied' : 'Copy'}</span>
                      </CopyToClipboard>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {user.partner && (
                <Row>
                  <Col>
                    <Form.Group className={cx('form_url')}>
                      <Form.Control
                        disabled
                        name="courseUrl"
                        type="text"
                        id="courseUrl"
                        placeholder="Digital Course URL"
                        value={`https://getnave.com/enroll${
                          user.partner.refId ? `?referral=${user.partner.refId}` : ''
                        }`}
                      />
                      <Form.Label htmlFor="courseUrl">
                        <span>Digital Course link</span>
                      </Form.Label>
                      <CopyToClipboard
                        text={`https://getnave.com/enroll${
                          user.partner.refId ? `?referral=${user.partner.refId}` : ''
                        }`}
                        onCopy={() => this.setState({ courseCopied: true })}
                      >
                        <span className="link">{this.state.courseCopied ? 'Copied' : 'Copy'}</span>
                      </CopyToClipboard>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className={cx('action_btn')}>
                  <div></div>
                  <Button variant="dark-blue" size="sm" onClick={() => this.saveAccInfo()}>
                    Save changes
                  </Button>
                </Col>
              </Row>
              <hr />
              <p>Change password</p>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <PasswordInput
                    autoComplete="new-password"
                    name="oldPassword"
                    id="oldPassword"
                    placeholder="Old password"
                    value={this.state.oldPassword}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <PasswordInput
                    autoComplete="new-password"
                    name="newPassword"
                    id="newPassword"
                    placeholder="New password"
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={cx('action_btn')}>
                  <Link to="/" className="btn btn-blue-border btn-sm">
                    Back
                  </Link>
                  <Button variant="dark-blue" size="sm" onClick={() => this.sendResetPassword()}>
                    Change password
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveAccInfo: (model) => dispatch(saveAccInfo(model)),
    changePassword: (model) => dispatch(changePassword(model)),
  };
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
