import apiCaller from '../../utils/apiCaller';
import errorHandler from '../Common/ErrorHandler';

// Export Constants
export const IS_LOADING = 'USERS_IS_LOADING',
  LIST = 'USERS_LIST',
  IS_ADDING = 'USERS_IS_ADDING';

export function getUsers() {
  return (dispatch) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });

    apiCaller
      .api('users', 'get')
      .then((res) => {
        dispatch({
          type: LIST,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
        dispatch(errorHandler.setError(err));
      });
  };
}

export function addUser(model) {
  return (dispatch) => {
    dispatch({
      type: IS_ADDING,
      payload: true,
    });

    return apiCaller
      .api('users/create', 'post', model)
      .then((res) => {
        dispatch(getUsers());
        dispatch({
          type: IS_ADDING,
          payload: false,
        });
        dispatch(
          errorHandler.setSuccessMessage({
            data: {
              auth: {
                msg: 'success.users.userAddedSuccessfully',
              },
            },
          })
        );

        return Promise.resolve();
      })
      .catch((err) => {
        dispatch({
          type: IS_ADDING,
          payload: false,
        });
        dispatch(errorHandler.setError(err));

        return Promise.reject();
      });
  };
}

export function deleteUser(id) {
  return (dispatch) => {
    apiCaller
      .api(`users/${id}`, 'delete')
      .then((res) => {
        dispatch(getUsers());
        dispatch(
          errorHandler.setSuccessMessage({
            data: {
              auth: {
                msg: 'success.users.userDeletedSuccessfully',
              },
            },
          })
        );
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}
