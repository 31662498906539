import { IS_LOADING, CHAT_FILTER } from './ChatFilterActions';

const INITIAL_STATE = {
  isLoading: true,
  filter: undefined,
};

const ChatFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING: {
      return { ...state, isLoading: action.payload || true };
    }
    case CHAT_FILTER:
      return {
        ...state,
        isLoading: false,
        filter: action.payload,
      };
    default:
      return { ...state };
  }
};

export default ChatFilterReducer;
