import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';

import Routes from './routes';
import auth from './utils/auth';
import history from './utils/history';
import errorHandle from './modules/Common/ErrorHandler';
import { getInfo } from './modules/Auth/AuthActions';

class App extends Component {
  componentDidMount() {
    auth.getAuth();
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && prevProps.error !== this.props.error) {
      toast.error(this.props.error.message || this.props.error, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        className: 'error_bar',
      });
      this.props.clean();
    }

    if (this.props.success && prevProps.success !== this.props.success) {
      toast.success(this.props.success.message || this.props.success, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        className: 'success_bar',
      });
      this.props.clean();
    }

    if (this.props.info && prevProps.info !== this.props.info) {
      toast.info(this.props.info.message || this.props.info, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        className: 'info_bar',
      });
      this.props.clean();
    }
  }

  render() {
    return (
      <div>
        <Helmet
          title="Nave"
          titleTemplate="%s | Partner Portal"
          meta={[
            { charset: 'utf-8' },
            {
              'http-equiv': 'X-UA-Compatible',
              content: 'IE=edge',
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
          ]}
        />
        <ToastContainer autoClose={6000} newestOnTop={true} />

        <Router history={history}>
          <Routes />
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clean: () => dispatch(errorHandle.clean()),
    getInfo: () => dispatch(getInfo()),
  };
};

function mapStateToProps(state) {
  return {
    error: state.errors.error,
    success: state.errors.success,
    info: state.errors.info,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
