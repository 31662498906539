import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ImageSize.scss';

class ImageSize extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onImgLoad = ({ target: img }) => {
    this.setState({
      width: img.width,
      height: img.height,
    });
  };

  render() {
    const { src, alt, onClick } = this.props;
    const { width, height } = this.state;

    return (
      <img
        onLoad={this.onImgLoad}
        src={src}
        className={width > height ? 'height_size_full' : 'width_size_full'}
        alt={alt}
        onClick={onClick}
      />
    );
  }
}

ImageSize.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default ImageSize;
