import React, { Component } from 'react';
import { Button, Col, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames/bind';

import Loading from '../../../components/Loading/Loading';
import { updateConfigAddOnED, getAccountConfig } from '../AccountsActions';
import styles from './ExecutiveDashboardAddOnConfig.scss';
let cx = classNames.bind(styles);

const DEFAULT_TRIAL_DAYS = 30;

class ExecutiveDashboardAddOnConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: props.visible,
    };
  }

  loadConfig = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.getAccountConfig().then((res) => {
          this.setState({
            isLoading: false,
            accountConfig: res,
            daysNumber: res.exportTrialEnd
              ? moment(res.exportTrialEnd).diff(moment(res.exportTrialStart), 'days')
              : DEFAULT_TRIAL_DAYS,
            trialEnd: res.exportTrialEnd
              ? moment(res.exportTrialEnd).format('DD MMM YYYY HH:mm')
              : moment().add(DEFAULT_TRIAL_DAYS, 'day').format('DD MMM YYYY HH:mm'),
          });
        });
      }
    );
  };

  onButtonClick = () => {
    if (!this.state.isOpened) {
      this.setState({ isOpened: true });
      this.loadConfig();
    }
  };

  onActivate = (event) => {
    event.stopPropagation();
    const config = {
      exportEnabled: true,
      exportTrialEnd: null,
      exportActivationDate: new Date().toISOString(),
    };

    this.props
      .updateConfigAddOnED(config)
      .then(() => {
        this.setState({ isOpened: false });
      })
      .catch(() => {});
  };

  onDeactivate = (event) => {
    event.stopPropagation();

    const config = {
      exportEnabled: false,
      exportActivationDate: null,
      exportDeactivationDate: new Date().toISOString(),
    };
    this.props
      .updateConfigAddOnED(config)
      .then(() => {
        this.setState({ isOpened: false });
      })
      .catch(() => {});
  };

  onClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ isOpened: false });
  };

  onStartTrial = (event) => {
    event.stopPropagation();
    const config = {
      exportEnabled: true,
      exportTrialStart: new Date().toISOString(),
      exportTrialEnd: moment().add(this.state.daysNumber, 'day').toISOString(),
    };
    this.props
      .updateConfigAddOnED(config)
      .then(() => {
        this.setState({ isOpened: false });
      })
      .catch(() => {});
  };

  onUpdateTrial = (event) => {
    event.stopPropagation();
    const config = {
      exportEnabled: true,
      exportTrialEnd: moment(this.state.accountConfig.exportTrialStart).add(this.state.daysNumber, 'day').toISOString(),
    };
    this.props
      .updateConfigAddOnED(config)
      .then(() => {
        this.setState({ isOpened: false });
      })
      .catch(() => {});
  };

  onEndTrial = (event) => {
    event.stopPropagation();
    const config = {
      exportEnabled: false,
      exportTrialEnd: new Date().toISOString(),
    };
    this.props
      .updateConfigAddOnED(config)
      .then(() => {
        this.setState({ isOpened: false });
      })
      .catch(() => {});
  };

  handleChange = (ev) => {
    this.setState({
      daysNumber: ev.target.value,
      trialEnd: moment().add(ev.target.value, 'day').format('DD MMM YYYY HH:mm'),
    });
  };

  render() {
    const { isOpened, accountConfig, isLoading } = this.state;
    const modal = (
      <Modal
        className={cx('ed-modal')}
        show={isOpened}
        onHide={this.onClose}
        keyboard={this.props.keyboard}
        backdrop={this.props.backdrop}
        enforceFocus={this.props.enforceFocus}
      >
        <Modal.Header>Enable Executive Dashboard Add-on</Modal.Header>
        <Modal.Body>
          {isLoading && <Loading />}
          {!isLoading && accountConfig && !accountConfig.exportActivationDate && (
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={4}>
                  <FormGroup controlId="days-number">
                    <label>Number of days</label>
                    <FormControl
                      type="number"
                      name="daysNumber"
                      value={this.state.daysNumber}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup controlId="trial-end">
                    <label>Trial Ends on</label>
                    <FormControl type="text" name="trialEnd" value={this.state.trialEnd} autoComplete="off" disabled />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className={cx('action_btn')}>
                  <div>
                    {!isLoading && accountConfig && accountConfig.exportDeactivationDate && (
                      <div>
                        <p>
                          The add-on has been deactivated on{' '}
                          {moment(accountConfig.exportDeactivationDate).format('DD MMM YYYY HH:mm')}
                        </p>
                      </div>
                    )}
                  </div>
                  {!accountConfig.exportEnabled && (
                    <Button variant="white" size="sm" onClick={this.onStartTrial}>
                      Start Trial
                    </Button>
                  )}
                  {accountConfig.exportEnabled && (
                    <Button variant="white" size="sm" onClick={this.onUpdateTrial}>
                      Save changes
                    </Button>
                  )}
                  {accountConfig.exportEnabled && (
                    <Button variant="white" size="sm" onClick={this.onEndTrial}>
                      End Trial
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          )}
          {!isLoading && accountConfig && accountConfig.exportActivationDate && (
            <div>
              <p>The Executive Dashboards add-on has been activated on </p>
              <p>{moment(accountConfig.exportActivationDate).format('DD MMM YYYY HH:mm')}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isLoading && accountConfig && !accountConfig.exportActivationDate && (
            <Button variant="blue" size="sm" onClick={this.onActivate}>
              Activate
            </Button>
          )}
          {!isLoading && accountConfig && accountConfig.exportActivationDate && (
            <Button variant="blue" size="sm" onClick={this.onDeactivate}>
              Deactivate
            </Button>
          )}
          <Button variant="white" size="sm" onClick={this.onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );

    let content;
    if (this.props.children) {
      var btn = React.Children.only(this.props.children);
      content = React.cloneElement(
        btn,
        {
          onClick: this.onButtonClick,
          style: this.props.style,
        },
        btn.props.children,
        modal
      );
    } else {
      content = (
        <Button onClick={this.onButtonClick} style={this.props.style}>
          {this.props.buttonText}
          {modal}
        </Button>
      );
    }
    return content;
  }
}

ExecutiveDashboardAddOnConfig.propTypes = {
  accountId: PropTypes.string.isRequired,
  buttonText: PropTypes.node,
  visible: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    accountConfig: state.accounts.account,
    isLoading: state.accounts.isLoading,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getAccountConfig: () => dispatch(getAccountConfig(props.accountId)),
    updateConfigAddOnED: (config) => dispatch(updateConfigAddOnED(props.accountId, config)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutiveDashboardAddOnConfig);
