import apiCaller from '../../utils/apiCaller';
import urlHelper from '../../utils/url';
import errorHandler from '../Common/ErrorHandler';

// Export Constants
export const IS_LOADING = 'PARTNERS_IS_LOADING',
  LIST = 'PARTNERS_LIST';

export function getPartners(opt = {}, noDispatch) {
  return (dispatch) => {
    !noDispatch && dispatch({ type: IS_LOADING });

    let url = '/partners';
    url += urlHelper.buildQuery(opt);

    return apiCaller
      .api(url, 'get')
      .then((res) => {
        if (noDispatch) {
          return res;
        }

        dispatch({
          type: LIST,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function deletePartner(id, opt) {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });

    const url = `/partners/${id}`;

    apiCaller
      .api(url, 'delete')
      .then((res) => {
        dispatch(getPartners(opt));
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
      });
  };
}

export function createPartner(model) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('email', model.email);
    formData.append('name', model.name);
    formData.append('company', model.company);
    formData.append('refId', model.refId);
    formData.append('image', model.image);
    if (model.type) {
      formData.append('type', model.type);
    }

    return apiCaller
      .apiWithFormData('/partners/create', formData)
      .then((res) => {
        dispatch(getPartners());

        return Promise.resolve();
      })
      .catch((err) => {
        dispatch(errorHandler.setError(err));
        return Promise.reject();
      });
  };
}
