import React, { Component } from "react";
import { Button, Col, Form, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';


import { downloadTrials } from '../AccountsActions';

class ExportTrialConfig extends Component{
    constructor(props){
        super(props);
        const todayIsEarlierThenWednesday = moment().weekday() < 2;
        const thisWednesday = moment().startOf('W').add(2,'day').endOf('day');
        const lastWednesday = todayIsEarlierThenWednesday ? thisWednesday.add(-7, 'day'): thisWednesday;
        const lastWednesdayString = lastWednesday
                                            .set('seconds', 0)
                                            .set('milliseconds', 0)
                                            .format('DD MMM YYYY HH:mm')
        const prevThursday = moment(lastWednesdayString).add(-6,'day').startOf('day');
        this.state = {
            isOpened: props.visible,
            value: { 
                from: prevThursday
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .format('DD MMM YYYY HH:mm'),
                to: lastWednesdayString
            }
        };
    }

    onButtonClick = () => {
        if (!this.state.isOpened) {
            this.setState({ isOpened: true });
        }
    }

    onClose = (event) => {
        if (event) {
          event.stopPropagation();
        }
        this.setState({ isOpened: false });
    
        if (typeof this.props.onClose === 'function') {
          this.props.onClose();
        }
      };
    
      onConfirm = (event) => {
        event.stopPropagation();
    
        this.props
          .downloadTrials(this.state.value.from, this.state.value.to)
          .then(() => {
            if (typeof this.props.onClose === 'function') {
              this.props.onClose(true);
            }
          })
          .catch(() => {});
      };

    handleDateInput = (e) => {
        this.setState({
          value: {
            ...this.state.value,
            [e.target.name]: e.target.value,
          },
        });
    };
    
    handleDateInputOut = (e) => {
       if (moment(e.target.value).valueOf() === 'Invalid date') {
        this.setState({
            value: {
              ...this.state.value,
              [e.target.name]: 'Invalid date',
            },
        });
        } else {
          this.setState({
            value: {
              ...this.state.value,
              [e.target.name]: moment(e.target.value).format('DD MMM YYYY HH:mm'),
            },
          });
        }
      };
      

    render(){
        const { isOpened, value } = this.state;
        const modal = 
        (<Modal     
            show={isOpened}
            onHide={this.onClose}
            keyboard={this.props.keyboard}
            backdrop={this.props.backdrop}
            enforceFocus={this.props.enforceFocus}>
            <Modal.Header>Define Export Dates</Modal.Header>
                <Modal.Body>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={6}>
                        <FormGroup controlId="range-start">
                            <label className="label-fix">Starts on</label>
                            <FormControl
                            type="text"
                            name="from"
                            value={value.from}
                            autoComplete="off"
                            onChange={this.handleDateInput}
                            onBlur={this.handleDateInputOut}
                            />                            
                        </FormGroup>
                        </Col>
                        <Col sm={6}>
                        <FormGroup controlId="range-end">
                            <label className="label-fix">Ends on</label>
                            <FormControl
                            type="text"
                            name="to"
                            value={value.to}
                            autoComplete="off"
                            onChange={this.handleDateInput}
                            onBlur={this.handleDateInputOut}
                            />                            
                        </FormGroup>
                        </Col>
                    </Row>
                </Form>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="blue" size="sm" onClick={this.onConfirm} disabled={!this.state.value.from || !this.state.value.to}>
                    Export
                </Button>
                <Button variant="white" size="sm" onClick={this.onClose}>
                    Close
                </Button>                
            </Modal.Footer>
        </Modal>);
        
        let content;
        if (this.props.children) {
            var btn = React.Children.only(this.props.children);
            content = React.cloneElement(
            btn,
            {
                onClick: this.onButtonClick,
                style: this.props.style,
            },
            btn.props.children,
            modal
            );
        } else {
            content = (
            <Button onClick={this.onButtonClick} style={this.props.style}>
                {this.props.buttonText}
                {modal}
            </Button>
            );
        }
        return content;      
    }
}

ExportTrialConfig.propTypes = {
    buttonText: PropTypes.node,
    onClose: PropTypes.func,
    visible: PropTypes.bool,
  };

const mapDispatchToProps = (dispatch, props) => {
  return {
    downloadTrials: (startDate, endDate) => dispatch(downloadTrials(startDate, endDate)),
  };
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportTrialConfig);