import { AUTH_USER, UNAUTH_USER, FORGOT_PASSWORD_REQUEST_SENT, RESET_PASSWORD_REQUEST } from './AuthActions';

const INITIAL_STATE = {
  isLoggedIn: true,
  rpSent: false,
  user: {
    accounts: [],
  },
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, isLoggedIn: true, user: action.payload };
    case UNAUTH_USER:
      return {
        ...state,
        isLoggedIn: false,
        user: {
          ...INITIAL_STATE.user,
        },
      };
    case RESET_PASSWORD_REQUEST:
      return { ...state, isLoggedIn: true };
    case FORGOT_PASSWORD_REQUEST_SENT:
      return { ...state, rpSent: true, isLoggedIn: false };
    default:
      return { ...state };
  }
};

export default AuthReducer;
