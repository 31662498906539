import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { NotFound, PublicLayout, PrivateLayout } from './components/AppLayouts';
import { Login, Activate, ForgotPass, ResetPass } from './modules/Auth/pages';
import Dashboard from './modules/Dashboard/Dashboard';
import Partners from './modules/Partners/Partners';
import Accounts from './modules/Accounts/Accounts';
import AccUsers from './modules/Accounts/Users';
import AccDashboards from './modules/Accounts/Dashboards';
// import Reports from './modules/Reports/Reports';
import Students from './modules/Students/Students';
import Account from './modules/Account/Account';
import Users from './modules/Users/Users';
import Notifications from './modules/Notifications/Notifications';
import Status from './modules/Status/Status';

const sessionRoutes = {
  Login: {
    component: Login,
    path: '/login',
  },
  Activate: {
    component: Activate,
    path: '/activate/:token',
  },
  ForgotPass: {
    component: ForgotPass,
    path: '/forgotpassword',
  },
  ResetPass: {
    component: ResetPass,
    path: '/reset/:token',
  },
};

const privateRoutes = {
  Dashboard: {
    component: Dashboard,
    path: '/',
  },
  Partners: {
    component: Partners,
    path: '/partners',
  },
  Accounts: {
    component: Accounts,
    path: '/accounts',
  },
  AccUsers: {
    component: AccUsers,
    path: '/accounts/:id/users',
  },
  AccDashboards: {
    component: AccDashboards,
    path: '/accounts/:id/dashboards',
  },
  // Reports: {
  //   component: Reports,
  //   path: '/reports'
  // },
  Students: {
    component: Students,
    path: '/students',
  },
  Account: {
    component: Account,
    path: '/account',
  },
  Users: {
    component: Users,
    path: '/users',
  },
  Notifications: {
    component: Notifications,
    path: '/notifications',
    admin: true
  },
  Status: {
    component: Status,
    path: '/status',
    admin: true,
  },
};

const publicRoutes = {};

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {_.map(publicRoutes, (route, key) => {
          const { component, path } = route;
          return (
            <Route
              exact
              path={path}
              key={key}
              render={(route) => <PublicLayout component={component} route={route} />}
            />
          );
        })}

        {_.map(privateRoutes, (route, key) => {
          const { component, path, admin } = route;
          return (
            <Route
              exact
              path={path}
              key={key}
              render={() =>
                this.props.auth.isLoggedIn ? (
                  <PrivateLayout admin={admin} component={component} route={route} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          );
        })}

        {_.map(sessionRoutes, (route, key) => {
          const { component, path } = route;
          return (
            <Route exact path={path} key={key} render={() => <PublicLayout component={component} route={route} />} />
          );
        })}

        <Route component={NotFound} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Routes);
