import { API_URL, api } from './apiCaller';

const isBrowser = typeof window !== 'undefined';
const log4javascript = isBrowser ? require('log4javascript') : undefined;

const logger = {
  setup() {
    if (!log4javascript) {
      console.log('log4javascript not defined');
      return;
    }

    window.myLogger = log4javascript.getLogger('Nave-Partner-Portal');

    var errorAjaxAppender = new log4javascript.AjaxAppender(`${API_URL}/log/error`);
    errorAjaxAppender.addHeader('Authorization', `${localStorage.getItem('usrt')}`);
    errorAjaxAppender.setThreshold(log4javascript.Level.ERROR);
    errorAjaxAppender.setTimed(true);
    errorAjaxAppender.setTimerInterval(1000);
    errorAjaxAppender.setSendAllOnUnload();

    window.myLogger.addAppender(errorAjaxAppender);
  },

  error(error) {
    if (window.myLogger) {
      window.myLogger.error(
        JSON.stringify({
          error: error,
          context: navigator.userAgent,
        })
      );
    }
  },
  report(error, info) {
    if (window.myLogger) {
      window.myLogger.error(
        JSON.stringify({
          message: error.message,
          stack: error.stack,
          info: info,
          context: navigator.userAgent,
        })
      );
    } else {
      api('/log/error', 'post', {
        message: error.message,
        stack: error.stack,
        info: info,
      });
    }
  },
};

export default logger;
