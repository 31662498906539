import React, { Component } from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames/bind';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import PasswordInput from '../../../components/PasswordInput';
import { resetPassword } from '../AuthActions';

import logo from '../img/nave.svg';
import loginImg from '../img/logo-image.svg';

// Import Style
import styles from '../Auth.scss';
const cx = classNames.bind(styles);

class ResetPass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      password: '',
    };
  }

  componentDidMount() {
    this.setState({
      token: this.props.match.params.token,
    });
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.resetPassword({
      token: this.state.token,
      password: this.state.password,
    });
  };

  render() {
    return (
      <div className={cx('login_bg')}>
        <div className={cx('login_form')}>
          <div className={cx('left_part')}>
            <Form className={cx('form')} noValidate onSubmit={this.handleSubmit}>
              <h1>Partner Portal</h1>
              <PasswordInput
                name="password"
                id="password"
                placeholder="New Password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <Button type="submit" className="mt-4" variant="blue">
                Reset
              </Button>
            </Form>
          </div>

          <div className={cx('right_part')}>
            <div className={cx('logo')}>
              <img src={logo} alt="" />
            </div>
            <div className={cx('logo_icon')}>
              <img src={loginImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (body) => dispatch(resetPassword(body)),
  };
};

export default withRouter(connect(undefined, mapDispatchToProps)(ResetPass));
