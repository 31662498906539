import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button } from 'react-bootstrap';

import { logout } from '../../modules/Auth/AuthActions';

import Apps from '../Apps/Apps';
import Profile from '../Profile/Profile';
import AddPartnerModal from '../../modules/Partners/components/AddPartnerModal/AddPartnerModal';

import logoNave from '../../assets/images/logo.svg';
import profileImage from '../../assets/images/default-image.svg';

import styles from './Header.scss';
let cx = classNames.bind(styles);

class Header extends Component {
  toggleMenu = () => {
    document.body.classList.add('menu-shown');
  };

  render() {
    const auth = this.props.auth;
    return (
      <div className={cx('header')}>
        <div>
          <div className={cx('logo')}>
            <Link to="/">
              <img src={logoNave} alt="#" />
            </Link>
          </div>
          <Button className={cx('btn_hamburger')} onClick={this.toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </Button>
        </div>

        <div className={cx('right_parth')}>
          {this.props.location.pathname === '/partners' && (
            <AddPartnerModal>
              <Button variant="blue" className={cx('add-partner')} size="sm">
                Add partner
              </Button>
            </AddPartnerModal>
          )}

          <Apps></Apps>
          <Profile
            user={auth.user.name}
            email={auth.user.email}
            profileImage={auth.user.photo || profileImage}
            linkToAccount="/account"
            linkToUsers="/users"
            onClick={this.props.logout}
          ></Profile>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
